import { all, call, put, takeLatest } from "redux-saga/effects";
import { authActions } from "../slices/authSlice";
import authService from "../../services/authService";

function* loginSaga({ payload }) {
  try {
    const data = yield call(authService.login, {
      email: payload.email,
      password: payload.password,
    });
    yield put(authActions.loginSuccess(data));
    payload.handleLoginSuccess(data);
  } catch (e) {
    yield put(authActions.loginError(e.response.data.errors));
  }
}

function* registerSaga({ payload }) {
  try {
    const data = yield call(authService.register, {
      email: payload.email,
      password: payload.password,
      passwordConfirm: payload.confirmPassword,
      firstName: payload.firstName,
      lastName: payload.lastName,
      gender: payload.gender,
      mobilePhoneNumber: payload.phoneNumber,
      address: payload.address,
      cityId: 1,
      countryId: 1,
      language: payload.language,
    });
    yield put(authActions.registerSuccess(data));
    payload.handleRegisterSuccess(data);
  } catch (e) {
    yield put(authActions.registerError(e.response.data.errors));
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    yield call(authService.resetPasswordUrl, { email: payload.email });
    yield put(authActions.resetPasswordSuccess());
    payload.handleResetPasswordSuccess();
  } catch (e) {
    yield put(
      authActions.resetPasswordError("Please enter valid email address")
    );
  }
}

function* allUsersSaga({ payload }) {
  try {
    const data = yield call(authService.allUsersUrl, {
      pageNumber: payload.pageNumber,
    });
    yield put(authActions.allUsersSuccess(data));
  } catch (e) {
    yield put(authActions.allUsersError(e.response.data.errors));
  }
}

function* changePasswordSaga({ payload }) {
  try {
    const data = yield call(authService.changePassword, {
      token: payload.token,
      email: payload.email,
      newPassword: payload.newPassword,
    });

    payload.onSuccessChangedPassword();
    yield put(authActions.changePasswordSuccess(data));
  } catch (e) {
    yield put(authActions.changePasswordError("Something went wrong"));
  }
}

function* createContactSaga({ payload }) {
  try {
    yield call(authService.createContact, {
      name: payload.name,
      company: payload.company,
      email: payload.email,
      message: payload.message,
    });

    payload.onCreateContactCallback(true);
    yield put(authActions.createContactSuccess());
  } catch (e) {
    payload.onCreateContactCallback(false);
    yield put(authActions.createContactError("Something went wrong!"));
  }
}

function* confirmEmailSaga({ payload }) {
  try {
    yield call(authService.emailConfirm, {
      token: payload.token,
      email: payload.email,
    });

    yield put(authActions.confirmEmailSuccess());
    payload.onSuccessEmailConfirmation();
  } catch (e) {
    yield put(authActions.confirmEmailError("Something went wrong!"));
  }
}

function* getUserByIdSaga() {
  try {
    const data = yield call(authService.getUserById);

    yield put(authActions.getUserByIdSuccess(data));
  } catch (e) {
    yield put(authActions.getUserByIdError("Something went wrong!"));
  }
}

function* updateUserSaga({ payload }) {
  try {
    yield call(authService.updateUser, {
      firstName: payload.firstName,
      lastName: payload.lastName,
      gender: payload.gender,
      address: payload.address,
      city: payload.city,
      country: payload.country,
      mobilePhoneNumber: payload.phoneNumber,
      password: payload.password,
      passwordConfirm: payload.confirmPassword,
      language: payload.language,
    });

    yield put(authActions.updateUserSuccess());
    payload.onSuccessUpdateUser();
  } catch (e) {
    yield put(authActions.updateUserError("Something went wrong!"));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest("auth/loginFetch", loginSaga),
    takeLatest("auth/registerFetch", registerSaga),
    takeLatest("auth/resetPasswordFetch", resetPasswordSaga),
    takeLatest("auth/allUsersFetch", allUsersSaga),
    takeLatest("auth/changePasswordFetch", changePasswordSaga),
    takeLatest("auth/createContactFetch", createContactSaga),
    takeLatest("auth/confirmEmailFetch", confirmEmailSaga),
    takeLatest("auth/getUserByIdFetch", getUserByIdSaga),
    takeLatest("auth/updateUserFetch", updateUserSaga),
  ]);
}
