import React, { useEffect, useState } from "react";
import Page from "./Page";
import Input from "../components/shared/Input";
import { Col, Row } from "react-bootstrap";
import Button from "../components/shared/Button";
import { Link } from "react-router-dom";
import {
  HOME_PAGE_URL,
  HOME_PAGE_URL_EMPTY,
  LOGIN_PAGE_URL,
  REGISTER_PAGE_URL,
} from "../constants/pagesConstants";
import { validateEmail, validateInput } from "../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/slices/authSlice";
import CustomSpinner from "../components/shared/CustomSpinner";
import { useNavigate } from "react-router-dom";
import { uiActions } from "../store/slices/uiSlice";
import axios from "axios";
import Modal from "../components/shared/Modal";
import ScrollToTop from "../components/shared/ScrollToTop";
// import ReCAPTCHA from "react-google-recaptcha";

// const REACT_APP_SITE_KEY = process.env.REACT_APP_API_KEY;

const Login = () => {
  const { loading, error, resetPasswordLoading, resetPasswordError } =
    useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordEmailMessage, setResetPasswordEmailMessage] =
    useState(null);
  const [checkEmailMessage, setCheckEmailMessage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(uiActions.setActiveLink(LOGIN_PAGE_URL));
  }, [dispatch]);

  const handleLoginSuccess = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
    navigate(HOME_PAGE_URL_EMPTY);
    dispatch(uiActions.setActiveLink(HOME_PAGE_URL));
  };

  useEffect(() => {
    return () => {
      dispatch(authActions.resetError());
    };
  }, [dispatch]);

  const submitFormHandler = (e) => {
    e.preventDefault();
    setEmailMessage(null);
    setPasswordMessage(null);
    dispatch(authActions.resetPasswordResetError());

    if (!validateEmail(email)) {
      setEmailMessage("Please enter valid email");
      return;
    }

    if (!validateInput(password)) {
      setPasswordMessage("Please enter valid password");
      return;
    }
    dispatch(authActions.loginFetch({ email, password, handleLoginSuccess }));
  };

  const toggleResetPasswordModal = () => {
    if (!resetPasswordModal) {
      setResetPasswordModal(true);
    } else {
      setResetPasswordEmail("");
      setResetPasswordModal(false);
      dispatch(authActions.resetPasswordResetError());
      setCheckEmailMessage(false);
    }
  };

  const handleResetPasswordSuccess = () => {
    setResetPasswordEmail("");
    setCheckEmailMessage(true);
  };

  const resetPasswordHandler = () => {
    setResetPasswordEmailMessage(null);

    if (!validateEmail(resetPasswordEmail)) {
      setResetPasswordEmailMessage("Please enter valid email");
      return;
    }

    dispatch(
      authActions.resetPasswordFetch({
        email: resetPasswordEmail,
        handleResetPasswordSuccess,
      })
    );
  };

  return (
    <Page
      className="center-alignment"
      classNameContainer="flex-direction-row center-alignment"
    >
      <ScrollToTop />
      {resetPasswordModal && (
        <Modal onClose={toggleResetPasswordModal}>
          <div className="center-alignment custom-border-bottom pb-2">
            <p className="font-size-lg font-bold color-dark">Reset password</p>
          </div>
          <div className="custom-border-bottom pb-2">
            {resetPasswordLoading && (
              <div className="pt-2 center-alignment">
                <CustomSpinner />
              </div>
            )}
            {!checkEmailMessage && !resetPasswordLoading && (
              <Input
                label="Your email address"
                labelClassName="color-dark"
                labelVisibility
                type="text"
                placeholder="ex. erminbronja@gmail.com"
                value={resetPasswordEmail}
                setValue={setResetPasswordEmail}
                message={resetPasswordEmailMessage || resetPasswordError}
              />
            )}
            {checkEmailMessage && !resetPasswordLoading && (
              <div className="center-alignment pt-2">
                <p className="color-dark font-size-md">
                  Please check your email inbox!
                </p>
              </div>
            )}
          </div>
          <div className="center-alignment">
            <Button
              value="Close"
              className={"home-cards-button mb-0 me-2"}
              onClick={toggleResetPasswordModal}
            />
            {!checkEmailMessage && (
              <Button
                value="Submit"
                className={"home-cards-button mb-0 ms-2"}
                onClick={resetPasswordHandler}
              />
            )}
          </div>
        </Modal>
      )}
      {!loading ? (
        <form onSubmit={submitFormHandler} className="w-100">
          <Row className="flex-column center-alignment">
            <Col lg="4" md="12" xs="12">
              <Input
                label="Email or username"
                labelVisibility
                type="text"
                placeholder="ex. erminbronja@gmail.com"
                value={email}
                setValue={setEmail}
                message={emailMessage}
              />
            </Col>
            <Col lg="4" md="12" xs="12">
              <Input
                label="Password"
                type="password"
                labelVisibility
                placeholder="ex. Password"
                value={password}
                setValue={setPassword}
                message={passwordMessage}
              />
            </Col>
            {/* <div className="center-alignment">
              <ReCAPTCHA sitekey={REACT_APP_SITE_KEY} onChange={() => {}} />
            </div> */}
            <Col lg="4" md="12" xs="12">
              <Button value="Login" type="submit" />
            </Col>
            {error && (
              <Col lg="4" md="12" xs="12">
                {error.map((err, index) => (
                  <p
                    className="center-alignment mb-3 font-size-md color-danger text-center"
                    key={index}
                  >
                    {err}
                  </p>
                ))}
              </Col>
            )}
            <Col
              lg="4"
              md="12"
              xs="12"
              className="btn-md center-alignment flex-direction-column"
            >
              <Link
                to={REGISTER_PAGE_URL}
                className="font-size-md color-dark font-italic"
              >
                Or register...
              </Link>
              <p
                onClick={toggleResetPasswordModal}
                className="font-size-md color-dark font-italic font-underline cursor-pointer"
              >
                Reset password
              </p>
            </Col>
          </Row>
        </form>
      ) : (
        <CustomSpinner />
      )}
    </Page>
  );
};

export default Login;
