import React from "react";

const Card = ({ classes, children }) => {
  return (
    <div className={`card w-100 border-radius-lg custom-bg-gray ${classes}`}>
      {children}
    </div>
  );
};

export default Card;
