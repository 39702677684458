import React, { useEffect } from "react";
import Page from "./Page";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/slices/authSlice";
import { Col, Row } from "react-bootstrap";
import CustomSpinner from "../components/shared/CustomSpinner";
import AdminUserPreview from "../components/admin/AdminUserPreview";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN_PANEL_PAGE_URL } from "../constants/pagesConstants";
import { uiActions } from "../store/slices/uiSlice";

const AdminPanel = () => {
  const {
    loading: userLoading,
    user,
    allUsers,
    pagesCount,
    allUsersLoading,
    allUsersError,
  } = useSelector((state) => state.auth);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(uiActions.setActiveLink(ADMIN_PANEL_PAGE_URL));
  }, [dispatch]);

  useEffect(() => {
    if (!userLoading && user) {
      dispatch(authActions.allUsersFetch({ pageNumber: id }));
    }
  }, [dispatch, user, userLoading, id]);

  const onNavigateHandler = (pageNumber) => {
    navigate(ADMIN_PANEL_PAGE_URL.replace(":id", Number(pageNumber)));
  };

  return (
    <Page>
      {(userLoading || allUsersLoading) && !allUsersError ? (
        <div className="center-alignment">
          <CustomSpinner />
        </div>
      ) : (
        <>
          {id && (
            <Row>
              {!allUsersError &&
                allUsers &&
                allUsers.map((user, index) => (
                  <Col key={index} lg="6" md="6" xs="12">
                    <AdminUserPreview
                      firstName={user.firstName}
                      lastName={user.lastName}
                      email={user.email}
                      phoneNumber={user.mobilePhoneNumber}
                      blocked={user.blocked}
                    />
                  </Col>
                ))}
              <div className="center-alignment mt-5">
                <button
                  className="pagination-arrow me-3"
                  disabled={id - 1 <= 0}
                  onClick={() => onNavigateHandler(id - 1)}
                >
                  <AiOutlineLeft size={30} />
                </button>
                <p
                  className="pagination-page font-size-md font-bold"
                  onClick={() => onNavigateHandler(1)}
                >
                  1
                </p>
                <p className="pagination-page font-size-md font-bold">...</p>
                <p
                  className="pagination-page font-size-md font-bold"
                  onClick={() => onNavigateHandler(pagesCount)}
                >
                  {pagesCount}
                </p>
                <button
                  className="pagination-arrow ms-3"
                  disabled={Number(id) === pagesCount}
                  onClick={() => onNavigateHandler(pagesCount)}
                >
                  <AiOutlineRight size={30} />
                </button>
              </div>
            </Row>
          )}
        </>
      )}
      {allUsersError && (
        <div className="center-alignment">
          <p>{allUsersError}</p>
        </div>
      )}
    </Page>
  );
};

export default AdminPanel;
