import React, { useEffect, useState } from "react";
import Page from "./Page";
import ScrollToTop from "../components/shared/ScrollToTop";
import Input from "../components/shared/Input";
import { Col, Row, Table } from "react-bootstrap";
import Button from "../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { transportActions } from "../store/slices/transportSlice";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { validateInput } from "../utils/validators";

const DailyStatistics = () => {
  const { dailyStatistics } = useSelector((state) => state.transport);
  const [day, setDay] = useState("");
  const [dayMessage, setDayMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(transportActions.resetDailyStatistics());
  }, [dispatch]);

  const submitStatisticsDailyHandler = () => {
    setDayMessage(null);

    const myDate = new Date(day);
    const splittedDay = myDate.getDate();
    const splittedMonth = myDate.getMonth() + 1;
    const splittedYear = myDate.getFullYear();

    if (!validateInput(day)) {
      setDayMessage("Please select date");
      return;
    }

    dispatch(
      transportActions.dailyStatisticsFetch({
        day: splittedDay,
        month: splittedMonth,
        year: splittedYear,
      })
    );
  };

  const selectIconHandler = (args) => {
    return (
      <p className="center-alignment">
        {args === true ? (
          <FaCheck size={30} color="green" />
        ) : (
          <IoMdClose size={30} color="red" />
        )}
      </p>
    );
  };

  return (
    <Page>
      <ScrollToTop />
      <div className="statistic-filters">
        <Row className="d-flex align-items-end">
          <Col lg="3" md="6" xs="12">
            <Input
              label="Select day"
              labelVisibility
              type={"date"}
              value={day}
              setValue={setDay}
              message={dayMessage}
              paragraphAbsolute
            />
          </Col>
          <Col lg="3" md="6" xs="12">
            <Button
              className="p-3"
              value="Submit"
              onClick={submitStatisticsDailyHandler}
            />
          </Col>
        </Row>
      </div>

      {dailyStatistics && (
        <div className="pb-5">
          <div className="table-div mt-5">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Address from</th>
                  <th>Address to</th>
                  <th>Approved</th>
                  <th>Canceled</th>
                  <th>Canceled note</th>
                  <th>Note</th>
                  <th>Transportation time</th>
                  <th>Price</th>
                  <th>Vehicle type</th>
                  <th>Transport type</th>
                </tr>
              </thead>
              <tbody>
                {dailyStatistics.transports &&
                  dailyStatistics.transports.length > 0 &&
                  dailyStatistics.transports.map((daily, index) => (
                    <tr key={index}>
                      <td>{daily?.addressFrom?.name}</td>
                      <td>{daily?.addressTo?.name}</td>
                      <td>{selectIconHandler(daily?.approved)}</td>
                      <td>{selectIconHandler(daily?.canceled)}</td>
                      <td>{daily?.cancelledNote}</td>
                      <td>{daily?.note}</td>
                      <td>{daily?.transportDateTime}</td>
                      <td>{daily?.price}</td>
                      <td>{daily?.vehicleType?.name}</td>
                      <td>{daily?.transportType?.name}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-between">
            <p className="font-size-md font-bold">
              Date: {dailyStatistics?.date?.split("T")[0]}
            </p>
            <p className="font-size-md font-bold">
              Sum: {dailyStatistics.sum}&euro;
            </p>
          </div>
        </div>
      )}
    </Page>
  );
};

export default DailyStatistics;
