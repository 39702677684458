import React from "react";

const Select = ({
  label,
  labelVisibility,
  placeholder,
  className,
  value,
  setValue,
  message,
  options = [],
  setIsTouched = null,
  paragraphAbsolute,
}) => {
  return (
    <div className="mb-2">
      <label
        className="font-size-md py-2 text-dark"
        style={{ display: labelVisibility ? "block" : "none" }}
      >
        {label}
      </label>
      <select
        className={`${className} select custom-border-all-dark outline-none border-radius-md p-3 w-100 custom-bg-transparent`}
        placeholder={placeholder}
        value={value}
        onBlur={() => {
          if (setIsTouched) setIsTouched(true);
        }}
        onChange={(e) => setValue(e.target.value)}
      >
        <option value={0} hidden>
          Select value
        </option>
        {options &&
          options.length > 0 &&
          options.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.title}
            </option>
          ))}
      </select>
      {message && (
        <p
          className={`font-italic text-danger ${
            paragraphAbsolute && "position-absolute"
          }`}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default Select;
