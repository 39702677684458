import React from "react";
import { AiFillUnlock, AiFillLock } from "react-icons/ai";

const AdminUserPreview = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  blocked,
}) => {
  return (
    <div className="admin-user-preview d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div>
        <p className="font-bold ">
          {firstName} {lastName}
        </p>
      </div>
      <div>
        <p className="font-italic">{email}</p>
      </div>
      <div>{phoneNumber}</div>
      <div>
        {blocked ? <AiFillUnlock size={30} /> : <AiFillLock size={30} />}
      </div>
    </div>
  );
};

export default AdminUserPreview;
