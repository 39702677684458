import { all, call, put, takeLatest } from "redux-saga/effects";
import transportService from "../../services/transportService";
import { transportActions } from "../slices/transportSlice";

function* requestTransportSaga({ payload }) {
  try {
    yield call(transportService.requestTransport, {
      addressFrom: payload.addressFrom,
      addressTo: payload.addressTo,
      numberOfPersons: payload.numberOfPersons,
      transportDateTime: payload.transportDateTime,
      transportType: payload.transportType,
      vehicleType: payload.vehicleType,
      note: payload.note,
    });
    yield put(transportActions.createTrasportSuccess());
    payload.onSucceededRequestedTransport();
    payload.toggleIsShownRequestTransportModal();
  } catch (e) {
    payload.toggleIsShownRequestTransportModal();
    yield put(transportActions.createTrasportError(e.response.data.errors));
  }
}

function* allTransportsSaga({ payload }) {
  try {
    let data = {};
    if (payload.role === "Admin") {
      data = yield call(transportService.allTransportsAdmin, payload.id);
    } else {
      data = yield call(transportService.allTransportsUser, payload.id);
    }
    yield put(transportActions.allTransportsSuccess(data));
  } catch (e) {
    yield put(transportActions.allTransportsError(e.response.data.errors));
  }
}

function* transportTypesSaga() {
  try {
    const data = yield call(transportService.transportTypes);
    yield put(transportActions.transportTypesSuccess(data));
  } catch (e) {
    yield put(transportActions.transportTypesError(e.response.data.errors));
  }
}

function* approveTransportSaga({ payload }) {
  try {
    yield call(transportService.approveTransport, { id: payload.id });
    yield put(transportActions.approveTransportSuccess(payload));
    payload.onSuccessApproveTransport();
  } catch (e) {
    yield put(transportActions.approveTransportError(e.response.data.errors));
  }
}

function* doneTransportSaga({ payload }) {
  try {
    yield call(transportService.doneTransport, { id: payload.id });
    yield put(transportActions.doneTransportSuccess(payload));
    payload.onSuccessDoneTransport();
  } catch (e) {
    yield put(transportActions.doneTransportError(e.response.data.errors));
  }
}

function* cancelTransportSaga({ payload }) {
  try {
    yield call(transportService.cancelTransport, {
      id: payload.id,
      reasonCanceled: payload.cancelationReason,
    });
    yield put(transportActions.cancelTransportSuccess(payload));
    payload.onSuccessCancelTransport();
  } catch (e) {
    yield put(transportActions.cancelTransportError(e.response.data.errors));
  }
}

function* dailyStatisticsSaga({ payload }) {
  try {
    const data = yield call(transportService.dailyStatistics, {
      day: payload.day,
      month: payload.month,
      year: payload.year,
    });

    yield put(transportActions.dailyStatisticsSuccess(data));
  } catch (e) {
    yield put(transportActions.dailyStatisticsError("Something went wrong!"));
  }
}

function* monthlyStatisticsSaga({ payload }) {
  try {
    const data = yield call(transportService.monthlyStatistics, {
      month: payload.month,
      year: payload.year,
    });

    yield put(transportActions.monthlyStatisticsSuccess(data));
  } catch (e) {
    yield put(transportActions.monthlyStatisticsError("Something went wrong!"));
  }
}

function* yearlyStatisticsSaga({ payload }) {
  try {
    const data = yield call(transportService.yearlyStatistics, {
      year: payload.year,
    });

    yield put(transportActions.yearlyStatisticsSuccess(data));
  } catch (e) {
    yield put(transportActions.yearlyStatisticsError("Something went wrong!"));
  }
}

export default function* transportSaga() {
  yield all([
    takeLatest("transport/createTrasportFetch", requestTransportSaga),
    takeLatest("transport/allTransportsFetch", allTransportsSaga),
    takeLatest("transport/transportTypesFetch", transportTypesSaga),
    takeLatest("transport/approveTransportFetch", approveTransportSaga),
    takeLatest("transport/doneTransportFetch", doneTransportSaga),
    takeLatest("transport/cancelTransportFetch", cancelTransportSaga),
    takeLatest("transport/dailyStatisticsFetch", dailyStatisticsSaga),
    takeLatest("transport/monthlyStatisticsFetch", monthlyStatisticsSaga),
    takeLatest("transport/yearlyStatisticsFetch", yearlyStatisticsSaga),
  ]);
}
