export const validateEmail = (value) => {
  return value.trim().length > 0;
};

export const validateInput = (value) => {
  return value && value.trim().length > 0;
};

export const validateInputNumber = (value) => {
  return Number(value) > 0;
};

export const validateInputDateTime = (value) => {
  return new Date(value) > new Date();
};
