import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import taxi9 from "../assets/images/taxi9.jpg";
import callicon from "../assets/images/call-icon.png";
import taxi5 from "../assets/images/taxi5.jpg";
import taxi6 from "../assets/images/taxi6.jpg";
import taxi7 from "../assets/images/taxi7.jpg";
import { Col, Container, Row } from "react-bootstrap";
import Card from "../components/shared/Card";
import logo from "../assets/images/logo.png";
import homeCardCar1 from "../assets/images/home-card-car-1.png";
import homeCardCar2 from "../assets/images/home-card-car-2.png";
import homeCardCar from "../assets/images/home-card-car.png";
import Button from "../components/shared/Button";
import Input from "../components/shared/Input";
import Select from "../components/shared/Select";
import {
  validateInput,
  validateInputDateTime,
  validateInputNumber,
} from "../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { transportActions } from "../store/slices/transportSlice";
import Modal from "../components/shared/Modal";
import { uiActions } from "../store/slices/uiSlice";
import { HOME_PAGE_URL, LOGIN_PAGE_URL } from "../constants/pagesConstants";
import { AiOutlineSearch } from "react-icons/ai";
import { locationActions } from "../store/slices/locationSlice";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Home = () => {
  const [sliderToggle, setSliderToggle] = useState(true);
  const sliderRef = useRef(null);
  const [homeCardSelected, setHomeCardSelected] = useState(0);
  const [addressTo, setAddressTo] = useState("");
  const [addressFrom, setAddressFrom] = useState("");
  const [addressFromMessage, setAddressFromMessage] = useState(null);
  const [addressToMessage, setAddressToMessage] = useState(null);
  const [addressMessage, setAddressMessage] = useState(null);
  const [numberOfPersons, setNumberOfPersons] = useState("");
  const [numberOfPersonsMessage, setNumberOfPersonsMessage] = useState(null);
  const [dateTime, setDateTime] = useState("");
  const [dateTimeMessage, setDateTimeMessage] = useState(null);
  const [note, setNote] = useState("");
  const [noteMessage, setNoteMessage] = useState(null);
  const [transportType, setTransportType] = useState(0);
  const [transportTypeMessage, setTransportTypeMessage] = useState(null);
  const [locationFromSelect, setLocationFromSelect] = useState("");
  const [locationToSelect, setLocationToSelect] = useState("");
  const { isShownModal } = useSelector((state) => state.ui);
  const { user } = useSelector((state) => state.auth);
  const { transportTypes, storedInputs, error } = useSelector(
    (state) => state.transport
  );
  const [isShownRequestTransportModal, setIsShownRequestTransportModal] =
    useState(false);
  const dispatch = useDispatch();
  const {
    locationFrom,
    selectedLocationFrom,
    locationTo,
    selectedLocationTo,
    routeCalculation,
    calculateRouteError,
  } = useSelector((state) => state.location);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(uiActions.setActiveLink(HOME_PAGE_URL));
  }, [dispatch]);

  useEffect(() => {
    if (user && storedInputs) {
      dispatch(
        transportActions.createTrasportFetch({
          ...storedInputs,
          onSucceededRequestedTransport,
          toggleIsShownRequestTransportModal,
        })
      );
      dispatch(transportActions.resetStoredInputs());
    } else {
      dispatch(transportActions.resetStoredInputs());
    }
  }, [user, storedInputs, dispatch]);

  useEffect(() => {
    dispatch(transportActions.transportTypesFetch());
  }, [dispatch]);

  useEffect(() => {
    dispatch(locationActions.resetLocations());
  }, [dispatch]);

  useEffect(() => {
    if (locationFromSelect.length > 0 && locationFrom) {
      const coordinatesFrom = locationFrom.find(
        (x) => x.id === locationFromSelect
      );

      dispatch(
        locationActions.setSelectedLocationFrom({
          id: coordinatesFrom.id,
          name: coordinatesFrom.placeName,
          latitude: coordinatesFrom.latitude,
          longitude: coordinatesFrom.longitude,
        })
      );
    }
  }, [locationFrom, dispatch, locationFromSelect]);

  useEffect(() => {
    if (locationToSelect.length > 0 && locationTo) {
      const coordinatesTo = locationTo.find((x) => x.id === locationToSelect);

      dispatch(
        locationActions.setSelectedLocationTo({
          id: coordinatesTo.id,
          name: coordinatesTo.placeName,
          latitude: coordinatesTo.latitude,
          longitude: coordinatesTo.longitude,
        })
      );
    }
  }, [locationTo, dispatch, locationToSelect]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSliderToggle((oldVal) => !oldVal);
      sliderRef.current.slickNext();
    }, [5000]);

    return () => {
      clearTimeout(timeout);
    };
  }, [sliderToggle]);

  useEffect(() => {
    if (selectedLocationFrom.id && selectedLocationTo.id) {
      dispatch(
        locationActions.calculateRouteFetch({
          firstLongitude: selectedLocationFrom.longitude,
          firstLatitude: selectedLocationFrom.latitude,
          secondLongitude: selectedLocationTo.longitude,
          secondLatitude: selectedLocationTo.latitude,
        })
      );
    }
  }, [selectedLocationFrom, selectedLocationTo, dispatch]);

  const toggleIsShownRequestTransportModal = () => {
    setIsShownRequestTransportModal((oldState) => !oldState);
  };

  const addressMessageHandler = () => {
    if (addressFromMessage) return addressFromMessage;
    else if (addressToMessage) return addressToMessage;
    else if (addressMessage) return addressMessage;
    return null;
  };

  const resetMessagesHandler = () => {
    setAddressFromMessage(null);
    setAddressToMessage(null);
    setAddressMessage(null);
    setTransportTypeMessage(null);
    setNumberOfPersonsMessage(null);
    setDateTimeMessage(null);
    setNoteMessage(null);
  };

  const selectHomeCardHandler = (id) => {
    resetMessagesHandler();
    setHomeCardSelected(id);
  };

  const onRequestTransportHandler = () => {
    resetMessagesHandler();

    if (!locationFromSelect || !locationToSelect) {
      setAddressMessage("Please select destination");
      return;
    }

    if (transportType === 0) {
      setTransportTypeMessage("Please select transport type");
      return;
    }

    if (!validateInputNumber(numberOfPersons)) {
      setNumberOfPersonsMessage("Please enter valid number of persons");
      return;
    }

    if (!validateInputDateTime(dateTime)) {
      setDateTimeMessage("Please enter valid date and time");
      return;
    }

    if (!validateInput(note)) {
      setNoteMessage("Please enter valid note");
      return;
    }

    const objectToSend = {
      addressFrom: selectedLocationFrom,
      addressTo: selectedLocationTo,
      numberOfPersons: Number(numberOfPersons),
      transportDateTime: dateTime,
      transportType: Number(transportType),
      vehicleType: Number(homeCardSelected),
      note,
      onSucceededRequestedTransport,
      toggleIsShownRequestTransportModal,
    };

    if (!user) {
      dispatch(transportActions.saveInputsUntilUserLogin(objectToSend));
      navigate(LOGIN_PAGE_URL);
      return;
    }
    dispatch(transportActions.createTrasportFetch(objectToSend));
  };

  const toggleModalHandler = () => {
    dispatch(uiActions.toggleHomeModal());
  };

  const onSucceededRequestedTransport = () => {
    setAddressFrom(1);
    setAddressTo(1);
    setTransportType(1);
    setNumberOfPersons("");
    setDateTime(null);
    setNote("");
    setHomeCardSelected(0);
  };

  const getLocationHandler = (place) => {
    if (!place)
      dispatch(locationActions.locationFetch({ location: addressFrom, place }));
    else
      dispatch(locationActions.locationFetch({ location: addressTo, place }));
  };

  const submitAddressHadnler = () => {
    setAddressFromMessage("");
    setAddressToMessage("");

    if (locationFromSelect === "") {
      setAddressFromMessage("Please enter from address");
      return;
    }

    if (locationToSelect === "") {
      setAddressToMessage("Please enter to address");
      return;
    }

    setAddressMessage(null);
    toggleModalHandler();
  };

  const addressMsg = addressMessageHandler();

  return (
    <div className="home-page">
      {isShownRequestTransportModal && (
        <Modal onClose={toggleIsShownRequestTransportModal}>
          <div className="text-center custom-border-bottom-dark">
            <h4 className="color-dark">Alert</h4>
          </div>
          <div className="p-2 custom-border-bottom-dark">
            <p className="font-size-md color-dark text-center">
              {error
                ? "Failed to create transfer, try again!"
                : "Successfuly created transfer request!"}
            </p>
          </div>
          <div className="modal-actions d-flex justify-content-end w-50 m-auto">
            <Button value={"OK"} onClick={toggleIsShownRequestTransportModal} />
          </div>
        </Modal>
      )}
      {isShownModal && (
        <Modal onClose={toggleModalHandler}>
          <div className="text-center">
            <h4 className="color-dark">Enter address data</h4>
          </div>
          <div className="custom-border-top">
            <Row>
              <Col lg="6" md="6" xs="12">
                <div className="d-flex align-items-end">
                  <Input
                    label="Address from"
                    labelVisibility
                    placeholder="ex. City center"
                    labelClassName="color-dark"
                    value={addressFrom}
                    setValue={setAddressFrom}
                    message={addressFromMessage}
                  />
                  <AiOutlineSearch
                    onClick={() => getLocationHandler(false)}
                    size={30}
                    className="color-dark mb-4 cursor-pointer ms-2"
                  />
                </div>

                {locationFrom && (
                  <Select
                    label="Select location"
                    labelVisibility
                    value={locationFromSelect}
                    setValue={setLocationFromSelect}
                    options={locationFrom.map((l) => ({
                      value: l.id,
                      title: l.placeName,
                    }))}
                  />
                )}
              </Col>
              <Col lg="6" md="6" xs="12">
                <div className="d-flex align-items-end">
                  <Input
                    label="Address to"
                    labelVisibility
                    placeholder="ex. Luxembourg Airport"
                    labelClassName="color-dark"
                    value={addressTo}
                    setValue={setAddressTo}
                    message={addressToMessage}
                  />
                  <AiOutlineSearch
                    onClick={() => getLocationHandler(true)}
                    size={30}
                    className="color-dark mb-4 cursor-pointer ms-2"
                  />
                </div>

                {locationTo && (
                  <Select
                    label="Select location"
                    labelVisibility
                    value={locationToSelect}
                    setValue={setLocationToSelect}
                    options={locationTo.map((l) => ({
                      value: l.id,
                      title: l.placeName,
                    }))}
                  />
                )}
              </Col>
              {calculateRouteError && (
                <div className="center-alignment">
                  <p>Something went wrong</p>
                </div>
              )}
              {routeCalculation && (
                <div className="center-alignment flex-direction-column mt-2 mb-2">
                  <p className="font-size-md color-dark font-bold">
                    Distance {routeCalculation.distance}km
                  </p>
                  <p className="font-size-md color-dark font-bold">
                    Price: {routeCalculation.price}&euro;
                  </p>
                </div>
              )}
            </Row>
            {addressMessage && (
              <p className="font-italic text-danger font-size-md text-center">
                {addressMessage}
              </p>
            )}
          </div>
          <div className="modal-actions custom-border-top d-flex justify-content-end w-100">
            <div className="w-auto d-flex">
              <Button
                value="Close"
                className={"home-cards-button mb-0 mojbutton"}
                onClick={toggleModalHandler}
              />
              <Button
                onClick={submitAddressHadnler}
                value="Submit"
                className={"home-cards-button mb-0"}
              />
            </div>
          </div>
        </Modal>
      )}
      {/* HOME SLIDER */}
      <Slider {...settings} ref={sliderRef}>
        <div className={`slider-image`}>
          <img src={taxi5} alt="taxi5" />
        </div>
        <div className={`slider-image`}>
          <img src={taxi6} alt="taxi6" />
        </div>
        <div className={`slider-image`}>
          <img src={taxi7} alt="taxi7" />
        </div>
      </Slider>

      {/* HOME TEXT */}
      <Container className="my-5">
        <Row>
          <Col
            lg="6"
            md="6"
            xs="12"
            className="center-alignment flex-direction-column home-text"
          >
            <div className="text-center mb-5">
              <h2 className="font-weight-bold">Do you need transfer?</h2>
            </div>
            <div>
              <p className="text-justify font-italic">
                Welcome to ODDO Transfer, where seamless airport transportation meets unparalleled luxury.
                At ODDO Transfer, we redefine the way you travel to and from the airport, ensuring that your
                journey is not just a transfer but an experience of sophistication and comfort.

                Booking with ODDO Transfer is a breeze. Our user-friendly website and mobile app make it convenient
                for you to reserve your ride with just a few clicks. We understand that your time is precious,
                and we strive to make your transportation experience as effortless as possible.
              </p>
            </div>
          </Col>
          <Col lg="6" md="6" xs="12">
            <div className="div-image p-5 custom-bg-gray">
              <img src={taxi9} alt="taxi9" />
            </div>
          </Col>
        </Row>
      </Container>

      {/* CARDS - NEW */}
      <div className="home-cards-wrapper">
        <Container>
          <Row className="pb-5 center-alignment">
            <div className="center-alignment flex-direction-column mb-5">
              <h1 className="font-bold-2 mb-3 text-center">
                There is a <span className="marked-text">ODDO Transfer</span> for everyone.
              </h1>
              <p className="font-size-md text-center">
                Do you want to travel with other, alone, or smoking car.
              </p>
            </div>
            {homeCardSelected > 0 && (
              <h1 className="font-bold-2 mb-5 text-center">
                Request <span className="marked-text">TRANSPORT</span>
              </h1>
            )}
            {(homeCardSelected === 0 || homeCardSelected === 1) && (
              <Col lg="4" md="6" xs="12" className="mb-3">
                <Card classes="home-cards">
                  <div className="home-cards-logo mb-4">
                    <img src={logo} alt="logo" />
                  </div>
                  <div>
                    <p className="text-center fw-bold mb-3">
                      With other
                    </p>
                  </div>
                  <div className="space-between-alignment home-cards-bottom-div">
                    <Button
                      value={homeCardSelected === 0 ? "More infos" : "Close"}
                      className={"home-cards-button"}
                      onClick={
                        homeCardSelected === 0
                          ? selectHomeCardHandler.bind(this, 1)
                          : selectHomeCardHandler.bind(this, 0)
                      }
                    />
                    <div>
                      <img src={homeCardCar1} alt="home-car" />
                    </div>
                  </div>
                </Card>
              </Col>
            )}
            {(homeCardSelected === 0 || homeCardSelected === 2) && (
              <Col lg="4" md="6" xs="12" className="mb-3">
                <Card classes="home-cards">
                  <div className="home-cards-logo mb-4">
                    <img src={logo} alt="logo" />
                  </div>
                  <div>
                    <p className="text-center fw-bold mb-3">
                      Alone
                    </p>
                  </div>
                  <div className="space-between-alignment home-cards-bottom-div">
                    <Button
                      value={homeCardSelected === 0 ? "More infos" : "Close"}
                      className={"home-cards-button"}
                      onClick={
                        homeCardSelected === 0
                          ? selectHomeCardHandler.bind(this, 2)
                          : selectHomeCardHandler.bind(this, 0)
                      }
                    />
                    <div>
                      <img src={homeCardCar2} alt="home-car" />
                    </div>
                  </div>
                </Card>
              </Col>
            )}

            {(homeCardSelected === 0 || homeCardSelected === 3) && (
              <Col lg="4" md="6" xs="12" className="mb-3">
                <Card classes="home-cards">
                  <div className="home-cards-logo mb-4">
                    <img src={logo} alt="logo" />
                  </div>
                  <div>
                    <p className="text-center fw-bold mb-3">
                      Smoking car
                    </p>
                  </div>
                  <div className="space-between-alignment home-cards-bottom-div">
                    <Button
                      value={homeCardSelected === 0 ? "More infos" : "Close"}
                      className={"home-cards-button"}
                      onClick={
                        homeCardSelected === 0
                          ? selectHomeCardHandler.bind(this, 3)
                          : selectHomeCardHandler.bind(this, 0)
                      }
                    />
                    <div>
                      <img src={homeCardCar} alt="home-car" />
                    </div>
                  </div>
                </Card>
              </Col>
            )}
            {homeCardSelected > 0 && (
              <Col lg="8" md="6" xs="12" className="mb-3">
                <Row>
                  <Col lg="6" md="12" xs="12">
                    <Input
                      label="Address"
                      labelVisibility
                      type="button"
                      placeholder="ex. City center"
                      value="Select places"
                      onClick={toggleModalHandler}
                      message={addressMsg}
                    />
                  </Col>
                  <Col lg="6" md="12" xs="12">
                    <Select
                      label="Transport type"
                      labelVisibility
                      placeholder="ex. Luxembourg Airport"
                      value={transportType}
                      setValue={setTransportType}
                      options={transportTypes.map((tt) => ({
                        value: tt.id,
                        title: tt.name,
                      }))}
                      message={transportTypeMessage}
                    />
                  </Col>
                  <Col lg="6" md="12" xs="12">
                    <Input
                      label="Number of persons"
                      labelVisibility
                      placeholder="ex. 4"
                      type={"number"}
                      value={numberOfPersons}
                      setValue={setNumberOfPersons}
                      message={numberOfPersonsMessage}
                    />
                  </Col>
                  <Col lg="6" md="12" xs="12">
                    <Input
                      label="Transfer date - time"
                      labelVisibility
                      placeholder="ex. 25/6/2023"
                      type={"datetime-local"}
                      value={dateTime}
                      setValue={setDateTime}
                      message={dateTimeMessage}
                    />
                  </Col>
                  <Col lg="6" md="12" xs="12">
                    <Input
                      label="Note"
                      labelVisibility
                      placeholder="ex. Note"
                      value={note}
                      setValue={setNote}
                      message={noteMessage}
                    />
                  </Col>
                  <div className="center-alignment">
                    <ReCAPTCHA sitekey="6Lc0PkkpAAAAAPppg4WhHPcodBN_q2Ta-KNT9sSy" onChange={() => { }} />
                  </div>
                  <Col
                    lg="12"
                    md="12"
                    xs="12"
                    className="d-flex align-items-end"
                  >
                    <Button
                      onClick={onRequestTransportHandler}
                      value={"Request transport"}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Row className="pb-5 center-alignment call-us-top">
            <div className="center-alignment flex-direction-column mb-5">
              <h1 className="font-bold-2 mb-3 text-center">
                If you need <span className="marked-text"> more info </span> you can <span className="marked-text"> call us </span>
              </h1>
              <p className="font-size-md text-center">
                <a href="tel:+352671171995"><img src={callicon} /></a>
              </p>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Home;
