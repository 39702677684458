import React, { useCallback, useEffect, useState } from "react";
import Page from "./Page";
import ScrollToTop from "../components/shared/ScrollToTop";
import { Col, Row } from "react-bootstrap";
import Input from "../components/shared/Input";
import Button from "../components/shared/Button";
import Select from "../components/shared/Select";
import { validateInput } from "../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/slices/authSlice";
import CustomSpinner from "../components/shared/CustomSpinner";

const EditUser = () => {
  const { user, userById, userByIdLoading, userByIdError } = useSelector(
    (state) => state.auth
  );
  const [firstName, setFirstName] = useState("");
  const [firstNameMessage, setFirstNameMessage] = useState(null);
  const [lastName, setLastName] = useState("");
  const [lastNameMessage, setLastNameMessage] = useState(null);
  const [gender, setGender] = useState("");
  const [genderMessage, setGenderMessage] = useState(null);
  const [address, setAddress] = useState("");
  const [addressMessage, setAddressMessage] = useState(null);
  const [city, setCity] = useState("");
  const [cityMessage, setCityMessage] = useState(null);
  const [country, setCountry] = useState("");
  const [countryMessage, setCountryMessage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState(null);
  const [language, setLanguage] = useState(0);
  const [languageOptions, setLanguageOptions] = useState([
    { value: "en", index: 0 },
    { value: "de", index: 1 },
    { value: "fr", index: 2 },
    { value: "lux", index: 3 },
  ]);
  const [languageMessage, setLanguageMessage] = useState(null);
  const dispatch = useDispatch();

  const populateStates = useCallback(() => {
    setFirstName(userById.firstName);
    setLastName(userById.lastName);
    setGender(userById.gender);
    setAddress(userById.address);
    // setCity(userById.city);
    // setCountry(userById.country);
    setPhoneNumber(userById.mobilePhoneNumber);
    setLanguage(userById.language);
  }, [userById]);

  useEffect(() => {
    if (user) dispatch(authActions.getUserByIdFetch());
  }, [dispatch, user]);

  useEffect(() => {
    if (userById) {
      populateStates();
    }
  }, [userById, populateStates]);

  const onSuccessUpdateUser = () => {
    //TODO Info Modal
  };

  const onEditUserHandler = () => {
    setFirstNameMessage(null);
    setLastNameMessage(null);
    setGenderMessage(null);
    setAddressMessage(null);
    setCityMessage(null);
    setCountryMessage(null);
    setPhoneNumberMessage(null);
    setPasswordMessage(null);
    setConfirmPasswordMessage(null);
    setConfirmPasswordMessage(null);
    setLanguageMessage(null);

    setLanguageOptions((oldState) => [...oldState]);

    if (!validateInput(firstName)) {
      setFirstNameMessage("Please enter valid first name");
      return;
    }

    if (!validateInput(lastName)) {
      setLastNameMessage("Please enter valid last name");
      return;
    }

    if (!validateInput(gender)) {
      setGenderMessage("Please enter valid gender");
      return;
    }

    if (!validateInput(address)) {
      setAddressMessage("Please enter valid address");
      return;
    }

    if (!validateInput(city)) {
      setCityMessage("Please enter valid city");
      return;
    }

    if (!validateInput(country)) {
      setCountryMessage("Please enter valid country");
      return;
    }

    if (!validateInput(phoneNumber)) {
      setPhoneNumberMessage("Please enter valid phone number");
      return;
    }

    if (!validateInput(password)) {
      setPasswordMessage("Please enter valid password");
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordMessage("Please enter same passwords");
      return;
    }

    if (!validateInput(confirmPassword)) {
      setConfirmPasswordMessage("Please enter valid password confirmation");
      return;
    }

    if (language === 0) {
      setLanguageMessage("Please select language");
      return;
    }

    dispatch(
      authActions.updateUserFetch({
        firstName,
        lastName,
        gender,
        address,
        city,
        country,
        phoneNumber,
        password,
        confirmPassword,
        language,
        onSuccessUpdateUser,
      })
    );
  };

  return (
    <Page>
      <ScrollToTop />
      {!userByIdLoading ? (
        <>
          {userById && !userByIdError && (
            <Row className="flex-column w-100 center-alignment">
              <Row className="center-alignment-top">
                <Col lg="3" md="12" xs="12">
                  <Input
                    label="FirstName"
                    labelVisibility
                    placeholder="ex. Ermin"
                    value={firstName}
                    setValue={setFirstName}
                    message={firstNameMessage}
                  />
                </Col>
                <Col lg="3" md="12" xs="12">
                  <Input
                    label="LastName"
                    labelVisibility
                    placeholder="ex. Bronja"
                    value={lastName}
                    setValue={setLastName}
                    message={lastNameMessage}
                  />
                </Col>
              </Row>
              <Row className="center-alignment-top">
                <Col lg="3" md="12" xs="12">
                  <Input
                    label="Gender"
                    labelVisibility
                    placeholder="ex. Male"
                    value={gender}
                    setValue={setGender}
                    message={genderMessage}
                  />
                </Col>
                <Col lg="3" md="12" xs="12">
                  <Input
                    label="Address"
                    labelVisibility
                    placeholder="ex. Kej skopskih zrtava 70  "
                    value={address}
                    setValue={setAddress}
                    message={addressMessage}
                  />
                </Col>
              </Row>

              <Row className="center-alignment-top">
                <Col lg="3" md="12" xs="12">
                  <Input
                    label="City"
                    labelVisibility
                    placeholder="ex. City center"
                    value={city}
                    setValue={setCity}
                    message={cityMessage}
                  />
                </Col>
                <Col lg="3" md="12" xs="12">
                  <Input
                    label="Country"
                    labelVisibility
                    placeholder="ex. Serbia"
                    value={country}
                    setValue={setCountry}
                    message={countryMessage}
                  />
                </Col>
              </Row>

              <Col lg="6" md="12" xs="12">
                <Input
                  label="Phone Number"
                  labelVisibility
                  placeholder="ex. +381 65 684 5788"
                  value={phoneNumber}
                  setValue={setPhoneNumber}
                  message={phoneNumberMessage}
                />
              </Col>

              <Col lg="6" md="12" xs="12">
                <Input
                  type={"password"}
                  label="Password"
                  labelVisibility
                  placeholder="Password"
                  value={password}
                  setValue={setPassword}
                  message={passwordMessage}
                />
              </Col>

              <Col lg="6" md="12" xs="12">
                <Input
                  type={"password"}
                  label="Password confirm"
                  labelVisibility
                  placeholder="Password confirm"
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  message={confirmPasswordMessage}
                />
              </Col>

              <Col lg="6" md="12" xs="12">
                <Select
                  label="Language"
                  labelVisibility
                  value={language}
                  setValue={setLanguage}
                  options={languageOptions.map((l) => ({
                    value: l.value,
                    title: l.value,
                  }))}
                  message={languageMessage}
                />
              </Col>

              <Col lg="6" md="12" xs="12">
                <Button
                  value="Edit User"
                  type="button"
                  onClick={onEditUserHandler}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col lg="4" md="12" xs="12">
              <p className="center-alignment mb-3 font-size-md color-danger text-center">
                {userByIdError}
              </p>
            </Col>
          </Row>
        </>
      ) : (
        <CustomSpinner />
      )}
    </Page>
  );
};

export default EditUser;
