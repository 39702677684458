import { API_URL } from "../../config/apiUrl";

export const authEndpoints = {
  login: `${API_URL}/identity/login`,
  register: `${API_URL}/identity/register`,
  resetPasswordUrl: `${API_URL}/identity/generateresetpasswordurl`,
  getAllUsers: `${API_URL}/identity/getall?pageNumber={pageNumber}`,
  changePassword: `${API_URL}/identity/resetpassword`,
  createContact: `${API_URL}/identity/contact`,
  emailConfirm: `${API_URL}/identity/emailconfirm?token={token}&email={email}`,
  getUserById: `${API_URL}/identity/get`,
  updateUser: `${API_URL}/identity/update`,
};

export const transportEndpoints = {
  requestTransport: `${API_URL}/transport/newtransport`,
  allTransportsAdmin: `${API_URL}/transport/getalltransportsadmin?pageNumber={id}`,
  allTransportsUser: `${API_URL}/transport/getalltransports?pageNumber={id}`,
  transportTypes: `${API_URL}/transport/gettransporttypes`,
  approveTransport: `${API_URL}/transport/confirmtransport`,
  doneTransport: `${API_URL}/transport/donetransport`,
  cancelTransport: `${API_URL}/transport/canceltransport`,
  dailyStatistics: `${API_URL}/transport/getdailystatistics/{day}/{month}/{year}`,
  monthlyStatistics: `${API_URL}/transport/getmonthlystatistics/{month}/{year}`,
  yearlyStatistics: `${API_URL}/transport/getyearlystatistics/{year}`,
};

export const locationEndpoints = {
  location: `${API_URL}/location/{text}`,
  calculateRoute: `${API_URL}/location/{longitude1}/{latitude1}/{longitude2}/{latitude2}`,
};
