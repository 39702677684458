import React, { useEffect, useState } from "react";
import Page from "./Page";
import { useDispatch, useSelector } from "react-redux";
import { CONTACT_PAGE_URL } from "../constants/pagesConstants";
import { uiActions } from "../store/slices/uiSlice";
import Input from "../components/shared/Input";
import Button from "../components/shared/Button";
import { Col, Row } from "react-bootstrap";
import { IoLocationSharp } from "react-icons/io5";
import { SlScreenSmartphone } from "react-icons/sl";
import { CiMail } from "react-icons/ci";
import ScrollToTop from "../components/shared/ScrollToTop";
import { validateEmail, validateInput } from "../utils/validators";
import { authActions } from "../store/slices/authSlice";
import Modal from "../components/shared/Modal";
import CustomSpinner from "../components/shared/CustomSpinner";

const Contact = () => {
  const [name, setName] = useState("");
  const [nameMessage, setNameMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState(null);
  const [company, setCompany] = useState("");
  const [companyMessage, setCompanyMessage] = useState(null);
  const [message, setMessage] = useState("");
  const [messageMessage, setMessageMessage] = useState(null);
  const { contactLoading, contactError } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uiActions.setActiveLink(CONTACT_PAGE_URL));
  }, [dispatch]);

  const onCreateContactCallback = (flag) => {
    setName("");
    setEmail("");
    setCompany("");
    setMessage("");
    toggleModalHandler();
  };

  const onSubmitFormHandler = (e) => {
    e.preventDefault();
    setNameMessage(null);
    setEmailMessage(null);
    setCompanyMessage(null);
    setMessageMessage(null);

    if (!validateInput(name)) {
      setNameMessage("Please enter valid name");
      return;
    }

    if (!validateEmail(email)) {
      setEmailMessage("Please enter valid email");
      return;
    }

    if (!validateInput(company)) {
      setCompanyMessage("Please enter valid company");
      return;
    }

    if (!validateInput(message)) {
      setMessageMessage("Please enter valid message");
      return;
    }

    dispatch(
      authActions.createContactFetch({
        name,
        company,
        email,
        message,
        onCreateContactCallback,
      })
    );
  };

  const toggleModalHandler = () => {
    setShowModal((oldState) => !oldState);
  };

  return (
    <Page>
      <ScrollToTop />
      {showModal && (
        <Modal onClose={toggleModalHandler}>
          <div className="text-center custom-border-bottom-dark">
            <h4 className="color-dark">Notification</h4>
          </div>
          <div className="p-2 custom-border-bottom-dark">
            <p className="font-size-md color-dark text-center">
              {contactError
                ? contactError
                : "Your message has been successfuly sent to our address. We will contact you soon."}
            </p>
          </div>
          <div className="modal-actions d-flex justify-content-end w-50 m-auto">
            <Button value={"OK"} onClick={toggleModalHandler} />
          </div>
        </Modal>
      )}
      <div>
        <div className="text-center">
          <h2>Contact us</h2>
        </div>
        <div className="my-5">
          <Row className="w-100">
            <Col lg="4" md="6" xs="12" className="mb-3">
              <div className="contact-card">
                <div className="contact-card-icon center-alignment mb-4">
                  <IoLocationSharp size={80} />
                </div>
                <div className="contact-card-title center-alignment mb-4">
                  OUR LOCATION
                </div>
                <div className="contact-card-text center-alignment">
                  Kej skopskih zrtava 70, Novi Pazar
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" xs="12" className="mb-3">
              <div className="contact-card">
                <div className="contact-card-icon center-alignment mb-4">
                  <SlScreenSmartphone size={80} />
                </div>
                <div className="contact-card-title center-alignment mb-4">
                  CALL US
                </div>
                <div className="contact-card-text center-alignment">
                  <a
                    className="contact-card-text-link"
                    href="tel:+381677177007"
                  >
                    +381 67 7177007
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" xs="12" className="mb-3">
              <div className="contact-card">
                <div className="contact-card-icon center-alignment mb-4">
                  <CiMail size={80} />
                </div>
                <div className="contact-card-title center-alignment mb-4">
                  E-MAIL US
                </div>
                <div className="contact-card-text center-alignment">
                  <a
                    className="contact-card-text-link"
                    href="mailto:harisbronja@gmail.com"
                  >
                    harisbronja@gmail.com
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          {contactLoading ? (
            <div className="center-alignment">
              <CustomSpinner />
            </div>
          ) : (
            <form onSubmit={onSubmitFormHandler}>
              <div>
                <Input
                  label="Name"
                  labelVisibility
                  type="text"
                  placeholder="ex. Haris Bronja"
                  value={name}
                  setValue={setName}
                  message={nameMessage}
                />
              </div>

              <div>
                <Input
                  label="Email"
                  labelVisibility
                  type="text"
                  placeholder="ex. harisbronja@gmail.com"
                  value={email}
                  setValue={setEmail}
                  message={emailMessage}
                />
              </div>

              <div>
                <Input
                  label="Company"
                  labelVisibility
                  type="text"
                  placeholder="ex. LuxTransport"
                  value={company}
                  setValue={setCompany}
                  message={companyMessage}
                />
              </div>

              <div className="contact-textarea d-flex flex-column mb-3">
                <label className="font-size-md py-2">Message</label>
                <textarea
                  rows="10"
                  className="p-2 font-size-md"
                  placeholder="Message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {messageMessage && (
                  <p className="font-italic text-danger">{messageMessage}</p>
                )}
              </div>

              <Button type="submit" value="Send us message" className="mb-5" />
            </form>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Contact;
