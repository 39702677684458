import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  storedInputs: null,
  dailyStatisticsloading: false,
  monthlyStatisticsloading: false,
  yearlyStatisticsloading: false,
  transport: null,
  dailyStatistics: null,
  monthlyStatistics: null,
  yearlyStatistics: null,
  error: null,
  dailyStatisticsError: null,
  monthlyStatisticsError: null,
  yearlyStatisticsError: null,

  // ALL TRANSPORTS
  allTransportsLoading: false,
  allTransports: [],
  pagesNumber: 1,
  currentPage: 1,
  allTransportsError: null,

  // TRANSPORT TYPES
  transportTypesLoading: false,
  transportTypes: [],
  transportTypesError: null,

  // APPROVE TRANSPORT
  approveTransportLoading: false,
  approveTransportError: null,

  // DONE TRANSPORT
  doneTransportLoading: false,
  doneTransportError: null,

  // CANCEL TRANSPORT
  cancelTransportLoading: false,
  cancelTransportError: null,
};

export const transportSlice = createSlice({
  name: "transport",
  initialState,
  reducers: {
    resetTransport: (state) => {
      state.loading = false;
      state.transport = null;
      state.error = null;
    },
    saveInputsUntilUserLogin: (state, action) => {
      state.storedInputs = action.payload;
    },
    resetStoredInputs: (state) => {
      state.storedInputs = null;
    },
    resetDailyStatistics: (state) => {
      state.dailyStatisticsloading = false;
      state.dailyStatistics = null;
      state.dailyStatisticsError = null;
    },
    resetMonthlyStatistics: (state) => {
      state.monthlyStatisticsloading = false;
      state.monthlyStatistics = null;
      state.monthlyStatisticsError = null;
    },
    resetYearlyStatistics: (state) => {
      state.yearlyStatisticsloading = false;
      state.yearlyStatistics = null;
      state.yearlyStatisticsError = null;
    },

    // CREATE TRANSPORT
    createTrasportFetch: (state) => {
      state.loading = true;
      state.error = null;
    },
    createTrasportSuccess: (state) => {
      state.loading = false;
      state.error = null;
    },
    createTrasportError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // GET DAILY STATISTICS
    dailyStatisticsFetch: (state) => {
      state.dailyStatisticsloading = true;
      state.dailyStatisticsError = null;
    },
    dailyStatisticsSuccess: (state, action) => {
      state.dailyStatisticsloading = false;
      state.dailyStatistics = action.payload;
      state.dailyStatisticsError = null;
    },
    dailyStatisticsError: (state, action) => {
      state.dailyStatisticsloading = false;
      state.dailyStatisticsError = action.payload;
    },

    // GET MONTHLY STATISTICS
    monthlyStatisticsFetch: (state) => {
      state.monthlyStatisticsloading = true;
      state.monthlyStatistics = null;
      state.monthlyStatisticsError = null;
    },
    monthlyStatisticsSuccess: (state, action) => {
      state.monthlyStatisticsloading = false;
      state.monthlyStatistics = action.payload;
      state.monthlyStatisticsError = null;
    },
    monthlyStatisticsError: (state, action) => {
      state.monthlyStatisticsloading = false;
      state.monthlyStatisticsError = action.payload;
    },

    // GET YEARLY STATISTICS
    yearlyStatisticsFetch: (state) => {
      state.yearlyStatisticsloading = true;
      state.yearlyStatisticsError = null;
    },
    yearlyStatisticsSuccess: (state, action) => {
      state.yearlyStatisticsloading = false;
      state.yearlyStatistics = action.payload;
      state.yearlyStatisticsError = null;
    },
    yearlyStatisticsError: (state, action) => {
      state.yearlyStatisticsloading = false;
      state.yearlyStatisticsError = action.payload;
    },

    // ALL TRANSPORTS
    resetAllTransports: (state) => {
      state.allTransportsLoading = false;
      state.allTransports = [];
      state.allTransportsError = null;
    },
    allTransportsFetch: (state) => {
      state.allTransportsLoading = true;
      state.allTransports = [];
      state.pagesNumber = 1;
      state.currentPage = 1;
      state.allTransportsError = null;
    },
    allTransportsSuccess: (state, action) => {
      state.allTransportsLoading = false;
      state.allTransports = action.payload.transports;
      state.pagesNumber = action.payload.pagesNumber;
      state.currentPage = action.payload.currentPage;
      state.allTransportsError = null;
    },
    allTransportsError: (state, action) => {
      state.allTransportsLoading = false;
      state.allTransports = [];
      state.pagesNumber = 1;
      state.currentPage = 1;
      state.allTransportsError = action.payload;
    },

    // TRANSPORT TYPES
    transportTypesFetch: (state) => {
      state.transportTypesLoading = true;
      state.transportTypes = [];
      state.transportTypesError = null;
    },
    transportTypesSuccess: (state, action) => {
      state.transportTypesLoading = false;
      state.transportTypes = action.payload;
      state.transportTypesError = null;
    },
    transportTypesError: (state, action) => {
      state.transportTypesLoading = false;
      state.transportTypes = [];
      state.transportTypesError = action.payload;
    },

    // APPROVE TRANSPORT
    approveTransportFetch: (state) => {
      state.approveTransportLoading = true;
      state.approveTransportError = null;
    },
    approveTransportSuccess: (state, action) => {
      state.approveTransportLoading = false;
      const id = action.payload.id;
      const transport = state.allTransports.find((x) => x.id === id);
      transport.approved = true;
      state.approveTransportError = null;
    },
    approveTransportError: (state, action) => {
      state.approveTransportLoading = false;
      state.approveTransportError = action.payload;
    },

    // DONE TRANSPORT
    doneTransportFetch: (state) => {
      state.doneTransportLoading = true;
      state.doneTransportError = null;
    },
    doneTransportSuccess: (state, action) => {
      state.doneTransportLoading = false;
      const id = action.payload.id;
      const transport = state.allTransports.find((x) => x.id === id);
      transport.transportIsDone = true;
      state.doneTransportError = null;
    },
    doneTransportError: (state, action) => {
      state.doneTransportLoading = false;
      state.doneTransportError = action.payload;
    },

    // CANCEL TRANSPORT
    cancelTransportFetch: (state) => {
      state.cancelTransportLoading = true;
      state.cancelTransportError = null;
    },
    cancelTransportSuccess: (state, action) => {
      state.cancelTransportLoading = false;
      const id = action.payload.id;
      const transport = state.allTransports.find((x) => x.id === id);
      transport.canceled = true;
      state.cancelTransportError = null;
    },
    cancelTransportError: (state, action) => {
      state.cancelTransportLoading = false;
      state.cancelTransportError = action.payload;
    },
  },
});

export const transportActions = transportSlice.actions;
export const transportReducers = transportSlice.reducer;
