import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import "./App.css";

import RouteHandler from "./components/route/RouteHandler";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  ABOUT_PAGE_URL,
  CONTACT_PAGE_URL,
  ADMIN_PANEL_PAGE_URL,
  HOME_PAGE_URL_EMPTY,
  LOGIN_PAGE_URL,
  REGISTER_PAGE_URL,
  TRANSPORTS_PAGE_URL,
  CHANGE_PASSWORD_PAGE_URL,
  EMAIL_CONFIRMATION_URL,
  DAILY_STATISTICS_URL,
  MONTHLY_STATISTICS_URL,
  YEARLY_STATISTICS_URL,
  EDIT_USER_URL,
} from "./constants/pagesConstants";
import Login from "./pages/Login";
import Home from "./pages/Home";
import AdminPanel from "./pages/AdminPanel";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { HOME_PAGE_URL } from "./constants/pagesConstants";
import Register from "./pages/Register";
import { authActions } from "./store/slices/authSlice";
import axios from "axios";
import Transports from "./pages/Transports";
import ChangePassword from "./pages/ChangePassword";
import EmailConfirm from "./pages/EmailConfirm";
import DailyStatistics from "./pages/DailyStatistics";
import MonthlyStatistics from "./pages/MonthlyStatistics";
import YearlyStatistics from "./pages/YearlyStatistics";
import EditUser from "./pages/EditUser";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (user) {
      const parsedUser = JSON.parse(user);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${parsedUser.token}`;
      dispatch(authActions.setUser(parsedUser));
    }
  }, [dispatch]);

  return (
    <>
      <RouteHandler>
        <Routes>
          <Route
            path={HOME_PAGE_URL_EMPTY}
            element={<Navigate replace to="/home" />}
          />
          <Route path={HOME_PAGE_URL} element={<Home />} />
          <Route path={ADMIN_PANEL_PAGE_URL} element={<AdminPanel />} />
          <Route path={ABOUT_PAGE_URL} element={<About />} />
          <Route path={CONTACT_PAGE_URL} element={<Contact />} />
          <Route path={TRANSPORTS_PAGE_URL} element={<Transports />} />
          <Route path={LOGIN_PAGE_URL} element={<Login />} />
          <Route path={CHANGE_PASSWORD_PAGE_URL} element={<ChangePassword />} />
          <Route path={EMAIL_CONFIRMATION_URL} element={<EmailConfirm />} />
          <Route path={DAILY_STATISTICS_URL} element={<DailyStatistics />} />
          <Route
            path={MONTHLY_STATISTICS_URL}
            element={<MonthlyStatistics />}
          />
          <Route path={YEARLY_STATISTICS_URL} element={<YearlyStatistics />} />
          <Route path={EDIT_USER_URL} element={<EditUser />} />
          <Route path={REGISTER_PAGE_URL} element={<Register />} />
          <Route path="*" element={<Navigate replace to="/home" />} />
        </Routes>
      </RouteHandler>
    </>
  );
};

export default App;
