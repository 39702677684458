import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="w-100 custom-bg-yellow py-5">
      <Container>
        <Row className="custom-border-bottom-black pb-2">
          <Col lg="4" md="8" xs="12" className="px-3 footer-col">
            <h5 className="pb-2 custom-border-bottom-black">
              About Lux Transport
            </h5>
            <p>
            Welcome to Oddo Transfer, where your journey begins with a touch 
            of luxury and arrives in style. At Oddo Transfer, we've crafted 
            a distinctive experience for your airport transfers – a blend of 
            sophistication, efficiency, and unmatched comfort.
            </p>
          </Col>
          <Col lg="2" md="4" xs="12" className="px-3 footer-col">
            <h5 className="pb-2 custom-border-bottom-black">What we do</h5>
            <p className="mb-4">Transfer request link</p>
            <p className="mb-4">Login link</p>
            <p className="mb-4">About link</p>
            <p className="mb-4">Home link</p>
          </Col>
          <Col lg="3" md="6" xs="12" className="px-3 footer-col">
            <h5 className="pb-2 custom-border-bottom-black">Contact us</h5>
            <p>Adress:</p>
            <p className="mb-2">Luxembourg, Luxembourg</p>
            <p>Phone:</p>
            <p className="mb-2">+352 671 171 995</p>
            <p>Email:</p>
            <p>email@gmail.com</p>
          </Col>
          <Col lg="3" md="6" xs="12" className="px-3 footer-col">
            <h5 className="pb-2 custom-border-bottom-black">
              Travel with us
            </h5>
            <p>
            Journey with Elegance, Arrive with Prestige – ODDO Transfer, Where Every Ride Is a Statement of Luxury and Style.
            </p>
          </Col>
        </Row>
        <div className="mt-5">
          <p className="text-center">
            Copyright &copy; {year} - All rights reserved - ODDO Transfer
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
