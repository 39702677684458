import React from "react";

const Button = ({ type = "button", value, className, onClick }) => {
  return (
    <button
      className={`my-2 p-3 w-100 outline-none custom-border-all-dark border-radius-md custom-bg-dark color-light ${className}`}
      type={type}
      onClick={onClick ?? null}
    >
      {value}
    </button>
  );
};

export default Button;
