import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // USER
  loading: false,
  user: null,
  error: null,

  // RESET PASSWORD
  resetPasswordLoading: false,
  resetPasswordError: null,

  // CONFIRM EMAIL
  confirmEmailLoading: false,
  confirmEmailError: null,

  // CHANGE PASSWORD
  changePasswordLoading: false,
  changePasswordError: null,

  // ALL USERS
  allUsersLoading: false,
  allUsers: [],
  pagesCount: 0,
  allUsersError: null,

  // CREATE CONTACT
  contactLoading: false,
  contactError: null,

  // GET USER BY ID
  userByIdLoading: false,
  userById: null,
  userByIdError: null,

  // UPDATE USER
  updateUserLoading: false,
  updateUserError: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // SHARED
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.loading = false;
      state.user = null;
      state.error = null;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetPasswordResetError: (state) => {
      state.resetPasswordError = null;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },

    // LOGIN
    loginFetch: (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    },
    loginError: (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
    },

    // REGISTER
    registerFetch: (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    },
    registerSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    },
    registerError: (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
    },

    // RESET PASSWORD
    resetPasswordFetch: (state) => {
      state.resetPasswordLoading = true;
      state.resetPasswordError = null;
    },
    resetPasswordSuccess: (state) => {
      state.resetPasswordLoading = false;
      state.resetPasswordError = null;
    },
    resetPasswordError: (state, action) => {
      state.resetPasswordLoading = false;
      state.resetPasswordError = action.payload;
    },

    // CHANGE PASSWORD
    changePasswordFetch: (state) => {
      state.changePasswordLoading = true;
      state.changePasswordError = null;
    },
    changePasswordSuccess: (state) => {
      state.changePasswordLoading = false;
      state.changePasswordError = null;
    },
    changePasswordError: (state, action) => {
      state.changePasswordLoading = false;
      state.changePasswordError = action.payload;
    },

    // CONFIRM EMAIL
    confirmEmailFetch: (state) => {
      state.confirmEmailLoading = true;
      state.confirmEmailError = null;
    },
    confirmEmailSuccess: (state) => {
      state.confirmEmailLoading = false;
      state.confirmEmailError = null;
    },
    confirmEmailError: (state) => {
      state.confirmEmailLoading = true;
      state.confirmEmailError = null;
    },

    // GET ALL USERS
    allUsersFetch: (state) => {
      state.allUsersLoading = true;
      state.allUsers = [];
      state.pagesCount = 0;
      state.allUsersError = null;
    },
    allUsersSuccess: (state, action) => {
      state.allUsersLoading = false;
      state.allUsers = action.payload.users;
      state.pagesCount = action.payload.pagesCount;
      state.allUsersError = null;
    },
    allUsersError: (state, action) => {
      state.allUsersLoading = false;
      state.allUsers = [];
      state.pagesCount = 0;
      state.allUsersError = action.payload;
    },

    // CREATE CONTACT
    createContactFetch: (state) => {
      state.contactLoading = true;
      state.contactError = null;
    },
    createContactSuccess: (state) => {
      state.contactLoading = false;
      state.contactError = null;
    },
    createContactError: (state, action) => {
      state.contactLoading = false;
      state.contactError = action.payload;
    },

    // GET USER BY ID FETCH
    getUserByIdFetch: (state) => {
      state.userByIdLoading = true;
      state.userById = null;
      state.userByIdError = null;
    },
    getUserByIdSuccess: (state, action) => {
      state.userByIdLoading = false;
      state.userById = action.payload;
      state.userByIdError = null;
    },
    getUserByIdError: (state, action) => {
      state.userByIdLoading = false;
      state.userById = null;
      state.userByIdError = action.payload;
    },

    // UPDATE USER
    updateUserFetch: (state) => {
      state.updateUserLoading = true;
      state.userByIdError = null;
    },
    updateUserSuccess: (state) => {
      state.updateUserLoading = false;
      state.updateUserError = null;
    },
    updateUserError: (state, action) => {
      state.updateUserLoading = false;
      state.updateUserError = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export const authReducers = authSlice.reducer;
