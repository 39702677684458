import React from "react";
import MyNavbar from "../shared/MyNavbar";
import Footer from "../shared/Footer";

const RouteHandler = ({ children }) => {
  return (
    <>
      <MyNavbar />
      {children}
      <Footer />
    </>
  );
};

export default RouteHandler;
