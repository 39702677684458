import React, { useEffect } from 'react'
import Page from './Page';
import { useDispatch } from 'react-redux';
import { ABOUT_PAGE_URL } from '../constants/pagesConstants';
import { uiActions } from '../store/slices/uiSlice';

const About = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(uiActions.setActiveLink(ABOUT_PAGE_URL));
  }, [dispatch])

  return (
    <Page>
      <div>
        <div className="text-center">
          <h2>About us</h2>
        </div>
        <div>
          {/* <table className="table table-striped table-dark">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table> */}
          <p>Welcome to Oddo Transfer, where your journey begins with a touch of luxury and arrives in style. At Oddo Transfer, we've crafted a distinctive experience for your airport transfers – a blend of sophistication, efficiency, and unmatched comfort.

            Embark on a travel encounter like no other as our fleet of meticulously maintained vehicles awaits to escort you seamlessly to and from the airport. From sleek sedans to spacious SUVs, our diverse range of high-end vehicles caters to your preferences, ensuring a tailored experience that suits your unique travel needs.

            Punctuality and professionalism define the core of our service. Our team of experienced, courteous drivers is dedicated to ensuring you reach your destination on time, every time. With Oddo Transfer, your airport transfer isn't just a ride; it's a journey marked by reliability and precision.

            Booking with Oddo Transfer is designed to be effortlessly convenient. Our user-friendly website and mobile app put the power of reservation at your fingertips, allowing you to secure your ride with ease. Because we understand that your time is valuable, we strive to make your transportation experience seamless and stress-free.

            Safety is our top priority. Our vehicles are equipped with cutting-edge safety features, and our drivers undergo rigorous training to guarantee your well-being throughout the journey. With Oddo Transfer, you can trust that your travels are in secure and capable hands.

            Whether you're a discerning business traveler seeking efficiency or a leisure traveler with a penchant for luxury, Oddo Transfer is your trusted partner for airport transportation. Indulge in the opulence you deserve and let us transform your airport transfers into an unparalleled travel experience.

            Choose Oddo Transfer for a journey that goes beyond the ordinary – where every ride is an expression of sophistication, comfort, and reliability. Welcome to a world where luxurious airport transfers become synonymous with the Oddo Transfer experience. Luxurious, punctual, and hassle-free – that's the Oddo Transfer commitment to you.</p>
        </div>
      </div>
    </Page>
  );
};

export default About