import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import {
  HOME_PAGE_URL,
  ADMIN_PANEL_PAGE_URL,
  HOME_PAGE_URL_EMPTY,
  LOGIN_PAGE_URL,
  ABOUT_PAGE_URL,
  CONTACT_PAGE_URL,
  GERMANY_LANGUAGE,
  FRANCE_LANGUAGE,
  TRANSPORTS_PAGE_URL,
  DAILY_STATISTICS_URL,
  MONTHLY_STATISTICS_URL,
  YEARLY_STATISTICS_URL,
  EDIT_USER_URL,
} from "../../constants/pagesConstants";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import germanyFlag from "../../assets/images/germany-flag.png";
import franceFlag from "../../assets/images/france-flag.png";
import { authActions } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { uiActions } from "../../store/slices/uiSlice";
import { NavDropdown } from "react-bootstrap";

const MyNavbar = () => {
  const { user } = useSelector((state) => state.auth);
  const { activeLink, activeLanguage } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(authActions.logoutUser());
    localStorage.removeItem("user");
    navigate(HOME_PAGE_URL_EMPTY);
    dispatch(uiActions.setActiveLink(HOME_PAGE_URL));
  };

  const setActiveLink = (activeLink) => {
    dispatch(uiActions.setActiveLink(activeLink));
  };

  const changeLanguageHandler = (language) => {
    dispatch(uiActions.setActiveLanguage(language));
  };

  return (
    <Navbar
      expand="lg"
      className="p-4 app-navbar custom-border-bottom-dark px-5"
      fixed="top"
    >
      <Container fluid>
        <Navbar.Brand
          to={HOME_PAGE_URL}
          as={Link}
          onClick={() => setActiveLink(HOME_PAGE_URL)}
        >
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link
              to={HOME_PAGE_URL}
              as={Link}
              onClick={() => setActiveLink(HOME_PAGE_URL)}
              className={`${
                activeLink === HOME_PAGE_URL ? "active-link" : ""
              } nav-custom-link`}
            >
              Home
            </Nav.Link>
            {/* {user && user.roles.includes("Admin") && (
              <Nav.Link
                to={ADMIN_PANEL_PAGE_URL.replace(":id", 1)}
                as={Link}
                onClick={() => setActiveLink(ADMIN_PANEL_PAGE_URL)}
                className={`${
                  activeLink === ADMIN_PANEL_PAGE_URL ? "active-link" : ""
                } nav-custom-link`}
              >
                Admin panel
              </Nav.Link>
            )} */}
            {user && (
              <NavDropdown title="Transports 1" id="basic-nav-dropdown">
                {user.roles.includes("Admin") && (
                  <NavDropdown.Item
                    as={Link}
                    to={ADMIN_PANEL_PAGE_URL.replace(":id", 1)}
                    onClick={() => setActiveLink(ADMIN_PANEL_PAGE_URL)}
                    className={`${
                      activeLink === ADMIN_PANEL_PAGE_URL ? "active-link" : ""
                    } nav-custom-link`}
                  >
                    Admin Panel
                  </NavDropdown.Item>
                )}

                <NavDropdown.Item
                  as={Link}
                  to={EDIT_USER_URL}
                  onClick={() => setActiveLink(EDIT_USER_URL)}
                  className={`${
                    activeLink === EDIT_USER_URL ? "active-link" : ""
                  } nav-custom-link`}
                >
                  User Panel
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {user && user.roles.includes("Admin") && (
              <Nav.Link
                to={TRANSPORTS_PAGE_URL.replace(":id", 1)}
                as={Link}
                onClick={() => setActiveLink(TRANSPORTS_PAGE_URL)}
                className={`${
                  activeLink === TRANSPORTS_PAGE_URL ? "active-link" : ""
                } nav-custom-link`}
              >
                Transports
              </Nav.Link>
            )}
            {
              <Nav.Link
                to={ABOUT_PAGE_URL}
                as={Link}
                onClick={() => setActiveLink(ABOUT_PAGE_URL)}
                className={`${
                  activeLink === ABOUT_PAGE_URL ? "active-link" : ""
                } nav-custom-link`}
              >
                About
              </Nav.Link>
            }
            {
              <Nav.Link
                to={CONTACT_PAGE_URL}
                as={Link}
                onClick={() => setActiveLink(CONTACT_PAGE_URL)}
                className={`${
                  activeLink === CONTACT_PAGE_URL ? "active-link" : ""
                } nav-custom-link`}
              >
                Contact
              </Nav.Link>
            }
            {user && user.roles.includes("Admin") && (
              <NavDropdown title="Statistics" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to={DAILY_STATISTICS_URL}>
                  Daily
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={MONTHLY_STATISTICS_URL}>
                  Monthly
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={YEARLY_STATISTICS_URL}>
                  Yearly
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {!user && (
              <Nav.Link
                to={LOGIN_PAGE_URL}
                as={Link}
                onClick={() => setActiveLink(LOGIN_PAGE_URL)}
                className={`${
                  activeLink === LOGIN_PAGE_URL ? "active-link" : ""
                } nav-custom-link`}
              >
                Login
              </Nav.Link>
            )}
            {user && (
              <Nav.Link onClick={handleLogout} className="nav-custom-link">
                Logout
              </Nav.Link>
            )}
            <div className="nav-languages custom-border-all-dark p-3 border-radius-md">
              <img
                src={germanyFlag}
                alt="germany-flag"
                className={`${
                  activeLanguage === GERMANY_LANGUAGE
                    ? "active-flag"
                    : "acive-flag"
                }`}
                onClick={() => changeLanguageHandler(GERMANY_LANGUAGE)}
              />
              <span className="mx-3">/</span>
              <img
                src={franceFlag}
                alt="france-flag"
                className={`${
                  activeLanguage === FRANCE_LANGUAGE ? "active-flag" : ""
                }`}
                onClick={() => changeLanguageHandler(FRANCE_LANGUAGE)}
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
