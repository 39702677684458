import axios from "axios";
import { authEndpoints } from "../store/requests/apiEndpoints";

const methods = {
  login: (payload) =>
    axios.post(authEndpoints.login, payload).then((response) => response.data),
  register: (payload) =>
    axios
      .post(authEndpoints.register, payload)
      .then((response) => response.data),
  resetPasswordUrl: (payload) =>
    axios
      .post(authEndpoints.resetPasswordUrl, payload)
      .then((response) => response.data),
  allUsersUrl: (payload) =>
    axios
      .get(
        authEndpoints.getAllUsers.replace("{pageNumber}", payload.pageNumber)
      )
      .then((response) => response.data),
  changePassword: (payload) =>
    axios
      .post(authEndpoints.changePassword, payload)
      .then((response) => response.data),
  createContact: (payload) =>
    axios
      .post(authEndpoints.createContact, payload)
      .then((response) => response.data),
  emailConfirm: (payload) =>
    axios
      .get(
        authEndpoints.emailConfirm
          .replace(
            "{token}",
            encodeURIComponent(decodeURIComponent(payload.token))
          )
          .replace("{email}", payload.email)
      )
      .then((response) => response.data),
  getUserById: () =>
    axios.get(authEndpoints.getUserById).then((response) => response.data),
  updateUser: (payload) =>
    axios
      .put(authEndpoints.updateUser, payload)
      .then((response) => response.data),
};

export default methods;
