import React, { useEffect, useState } from "react";
import Page from "./Page";
import ScrollToTop from "../components/shared/ScrollToTop";
import { Col, Row, Table } from "react-bootstrap";
import Button from "../components/shared/Button";
import Select from "../components/shared/Select";
import { useDispatch, useSelector } from "react-redux";
import { transportActions } from "../store/slices/transportSlice";

const YearlyStatistics = () => {
  const { yearlyStatistics, yearlyStatisticsError } = useSelector(
    (state) => state.transport
  );
  const [year, setYear] = useState(0);
  const [yearMessage, setYearMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(transportActions.resetYearlyStatistics());
  }, [dispatch]);

  const getYear = () => new Date().getFullYear();

  const years = [];
  for (let i = 2023; i <= getYear(); i++) {
    years.push({ value: i });
  }

  const yearlyStatisticsHandler = () => {
    setYearMessage(null);

    if (year === 0) {
      setYearMessage("Please enter year");
      return;
    }

    dispatch(transportActions.yearlyStatisticsFetch({ year }));
  };

  return (
    <Page>
      <ScrollToTop />
      <div className="statistic-filters">
        <Row className="d-flex align-items-end">
          <Col lg="3" md="6" xs="12">
            <Select
              label="Select year"
              labelVisibility
              value={year}
              setValue={setYear}
              options={years.reverse().map((y) => ({
                value: y.value,
                title: y.value,
              }))}
              message={yearMessage}
              paragraphAbsolute
            />
          </Col>
          <Col lg="3" md="6" xs="12">
            <Button
              className="p-3"
              value="Submit"
              onClick={yearlyStatisticsHandler}
            />
          </Col>
        </Row>
      </div>

      {yearlyStatistics && (
        <div className="pb-5">
          <div className="table-div mt-5 yearly-statistics-table">
            <Table striped bordered hover className="monthly-statistic-table">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {yearlyStatistics.months &&
                  yearlyStatistics.months.length > 0 &&
                  yearlyStatistics.months.map((yearly, index) => (
                    <tr key={index}>
                      <td>{yearly?.monthName}</td>
                      <td>{yearly?.price}&euro;</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-between">
            <p className="font-size-md font-bold">
              Year: {yearlyStatistics?.year}
            </p>
            <p className="font-size-md font-bold">
              Sum: {yearlyStatistics?.sum}
            </p>
          </div>
        </div>
      )}
      {yearlyStatisticsError && <p>{yearlyStatisticsError}</p>}
    </Page>
  );
};

export default YearlyStatistics;
