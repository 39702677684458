import { all, call, put, takeLatest } from "redux-saga/effects";
import locationService from "../../services/locationService";
import { locationActions } from "../slices/locationSlice";

function* getLocationSaga({ payload }) {
  try {
    const data = yield call(locationService.location, {
      location: payload.location,
    });
    yield put(locationActions.locationSuccess({ data, place: payload.place }));
  } catch (e) {
    yield put(locationActions.locationError(e.response.data.errors));
  }
}

function* getRouteCalculationSaga({ payload }) {
  try {
    const data = yield call(locationService.routeCalculation, {
      firstLongitude: payload.firstLongitude,
      firstLatitude: payload.firstLatitude,
      secondLongitude: payload.secondLongitude,
      secondLatitude: payload.secondLatitude,
    });
    yield put(locationActions.calculateRouteSuccess(data));
  } catch (e) {
    yield put(locationActions.calculateRouteError(e.response.data.errors));
  }
}

export default function* locationSaga() {
  yield all([
    takeLatest("location/locationFetch", getLocationSaga),
    takeLatest("location/calculateRouteFetch", getRouteCalculationSaga),
  ]);
}
