import axios from "axios";
import { transportEndpoints } from "../store/requests/apiEndpoints";

const methods = {
  requestTransport: (payload) =>
    axios
      .post(transportEndpoints.requestTransport, payload)
      .then((response) => response.data),
  allTransportsAdmin: (payload) =>
    axios
      .get(transportEndpoints.allTransportsAdmin.replace("{id}", payload))
      .then((response) => response.data),
  allTransportsUser: (payload) =>
    axios
      .get(transportEndpoints.allTransportsUser.replace("{id}", payload))
      .then((response) => response.data),
  transportTypes: () =>
    axios
      .get(transportEndpoints.transportTypes)
      .then((response) => response.data),
  approveTransport: (payload) =>
    axios
      .put(transportEndpoints.approveTransport, payload)
      .then((response) => response.data),
  doneTransport: (payload) =>
    axios
      .put(transportEndpoints.doneTransport, payload)
      .then((response) => response.data),
  cancelTransport: (payload) =>
    axios
      .put(transportEndpoints.cancelTransport, payload)
      .then((response) => response.data),
  dailyStatistics: (payload) =>
    axios
      .get(
        transportEndpoints.dailyStatistics
          .replace("{day}", payload.day)
          .replace("{month}", payload.month)
          .replace("{year}", payload.year)
      )
      .then((response) => response.data),
  monthlyStatistics: (payload) =>
    axios
      .get(
        transportEndpoints.monthlyStatistics
          .replace("{month}", payload.month)
          .replace("{year}", payload.year)
      )
      .then((response) => response.data),
  yearlyStatistics: (payload) =>
    axios
      .get(transportEndpoints.yearlyStatistics.replace("{year}", payload.year))
      .then((response) => response.data),
};

export default methods;
