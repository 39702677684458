import React from "react";
import ReactDOM from "react-dom";

const Backdrop = ({ onClick }) => {
  return <div className="modal-backdrop" onClick={onClick} />;
};

const ModalOverlay = ({ children }) => {
  return (
    <div className="custom-modal custom-bg-yellow color-light">
      <div>{children}</div>
    </div>
  );
};

const Modal = ({ children, onClose }) => {
  const portalElement = document.getElementById("overlay");

  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClick={onClose} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay>{children}</ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;
