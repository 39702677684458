import React, { useEffect, useState } from "react";
import Page from "./Page";
import { Col, Row } from "react-bootstrap";
import Input from "../components/shared/Input";
import Button from "../components/shared/Button";
import { validateEmail, validateInput } from "../utils/validators";
import { authActions } from "../store/slices/authSlice";
import {
  HOME_PAGE_URL_EMPTY,
  LOGIN_PAGE_URL,
} from "../constants/pagesConstants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "../components/shared/CustomSpinner";
import axios from "axios";
import Select from "../components/shared/Select";
import { uiActions } from "../store/slices/uiSlice";
import ReCAPTCHA from "react-google-recaptcha";

const REACT_APP_SITE_KEY = process.env.REACT_APP_API_KEY;

const optionsLanguage = [
  { value: "en", title: "EN" },
  { value: "de", title: "DE" },
  { value: "fr", title: "GR" },
];

const Register = () => {
  const { loading, error } = useSelector((state) => state.auth);
  const [firstName, setFirstName] = useState("Ermin");
  const [firstNameMessage, setFirstNameMessage] = useState(null);
  const [lastName, setLastName] = useState("Bronja");
  const [lastNameMessage, setLastNameMessage] = useState(null);
  const [gender, setGender] = useState("M");
  const [genderMessage, setGenderMessage] = useState(null);
  const [address, setAddress] = useState("Kej skopskih zrtava 70");
  const [addressMessage, setAddressMessage] = useState(null);
  const [city, setCity] = useState("Novi Pazar");
  const [cityMessage, setCityMessage] = useState(null);
  const [country, setCountry] = useState("Srbija");
  const [countryMessage, setCountryMessage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("0000000000");
  const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);
  const [email, setEmail] = useState("bronjaermin@gmail.com");
  const [emailMessage, setEmailMessage] = useState(null);
  const [password, setPassword] = useState("ErminBronja1!");
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("ErminBronja1!");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState(null);
  const [isTouchedLanguage, setIsTouchedLanguage] = useState(false);
  const [language, setLanguage] = useState("en");
  const [languageMessage, setLanguageMessage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(uiActions.setActiveLink(LOGIN_PAGE_URL));
  }, [dispatch]);

  const handleRegisterSuccess = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
    navigate(HOME_PAGE_URL_EMPTY);
  };

  useEffect(() => {
    return () => {
      dispatch(authActions.resetError());
    };
  }, [dispatch]);

  const submitFormHandler = (e) => {
    e.preventDefault();
    setFirstNameMessage(null);
    setLastNameMessage(null);
    setGenderMessage(null);
    setAddressMessage(null);
    setCityMessage(null);
    setCountryMessage(null);
    setPhoneNumberMessage(null);
    setEmailMessage(null);
    setPasswordMessage(null);
    setConfirmPasswordMessage(null);

    if (!validateInput(firstName)) {
      setFirstNameMessage("Please enter valid first name");
      return;
    }

    if (!validateInput(lastName)) {
      setLastNameMessage("Please enter valid last name");
      return;
    }

    if (!validateInput(gender)) {
      setGenderMessage("Please enter valid gender");
      return;
    }

    if (!validateInput(address)) {
      setAddressMessage("Please enter valid address");
      return;
    }

    if (!validateInput(city)) {
      setCityMessage("Please enter valid city");
      return;
    }

    if (!validateInput(country)) {
      setCountryMessage("Please enter valid country");
      return;
    }

    if (!validateInput(phoneNumber)) {
      setPhoneNumberMessage("Please enter valid phone number");
      return;
    }

    if (!validateEmail(email)) {
      setEmailMessage("Please enter valid email");
      return;
    }

    if (!validateInput(password)) {
      setPasswordMessage("Please enter valid password");
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordMessage("Please enter same passwords");
      return;
    }

    if (!validateInput(confirmPassword)) {
      setConfirmPasswordMessage("Please enter valid password confirmation");
      return;
    }

    if (!isTouchedLanguage) {
      setLanguageMessage("Please select language");
      return;
    }

    dispatch(
      authActions.registerFetch({
        firstName,
        lastName,
        gender,
        address,
        city,
        country,
        phoneNumber,
        email,
        password,
        confirmPassword,
        language,
        handleRegisterSuccess,
      })
    );
  };

  return (
    <Page
      className="center-alignment"
      classNameContainer="flex-direction-row center-alignment"
    >
      {!loading ? (
        <Row className="flex-column w-100 center-alignment">
          <Row className="center-alignment-top">
            <Col lg="3" md="12" xs="12">
              <Input
                label="FirstName"
                labelVisibility
                placeholder="ex. Ermin"
                value={firstName}
                setValue={setFirstName}
                message={firstNameMessage}
              />
            </Col>
            <Col lg="3" md="12" xs="12">
              <Input
                label="LastName"
                labelVisibility
                placeholder="ex. Bronja"
                value={lastName}
                setValue={setLastName}
                message={lastNameMessage}
              />
            </Col>
          </Row>
          <Row className="center-alignment-top">
            <Col lg="3" md="12" xs="12">
              <Input
                label="Gender"
                labelVisibility
                placeholder="ex. Male"
                value={gender}
                setValue={setGender}
                message={genderMessage}
              />
            </Col>
            <Col lg="3" md="12" xs="12">
              <Input
                label="Address"
                labelVisibility
                placeholder="ex. Kej skopskih zrtava 70  "
                value={address}
                setValue={setAddress}
                message={addressMessage}
              />
            </Col>
          </Row>

          <Row className="center-alignment-top">
            <Col lg="3" md="12" xs="12">
              <Input
                label="City"
                labelVisibility
                placeholder="ex. City center"
                value={city}
                setValue={setCity}
                message={cityMessage}
              />
            </Col>
            <Col lg="3" md="12" xs="12">
              <Input
                label="Country"
                labelVisibility
                placeholder="ex. Serbia"
                value={country}
                setValue={setCountry}
                message={countryMessage}
              />
            </Col>
          </Row>

          <Col lg="6" md="12" xs="12">
            <Input
              label="Phone Number"
              labelVisibility
              placeholder="ex. +381 65 684 5788"
              value={phoneNumber}
              setValue={setPhoneNumber}
              message={phoneNumberMessage}
            />
          </Col>

          <Col lg="6" md="12" xs="12">
            <Input
              type={"email"}
              label="Email"
              labelVisibility
              placeholder="ex. erminbronja@gmail.com"
              value={email}
              setValue={setEmail}
              message={emailMessage}
            />
          </Col>

          <Col lg="6" md="12" xs="12">
            <Input
              type={"password"}
              label="Password"
              labelVisibility
              placeholder="Password"
              value={password}
              setValue={setPassword}
              message={passwordMessage}
            />
          </Col>

          <Col lg="6" md="12" xs="12">
            <Input
              type={"password"}
              label="Password confirm"
              labelVisibility
              placeholder="Password confirm"
              value={confirmPassword}
              setValue={setConfirmPassword}
              message={confirmPasswordMessage}
            />
          </Col>

          <Col lg="6" md="12" xs="12">
            <Select
              label="Language"
              labelVisibility
              placeholder="ex. English"
              value={language}
              setValue={setLanguage}
              options={optionsLanguage}
              message={languageMessage}
              setIsTouched={setIsTouchedLanguage}
            />
          </Col>

          <div className="center-alignment">
            <ReCAPTCHA sitekey={REACT_APP_SITE_KEY} onChange={() => { }} />
          </div>

          <Col lg="6" md="12" xs="12">
            <Button
              value="Register"
              type="button"
              onClick={submitFormHandler}
            />
          </Col>
          {error && (
            <Col lg="4" md="12" xs="12">
              {error.map((err, index) => (
                <p
                  className="center-alignment mb-3 font-size-md color-danger text-center"
                  key={index}
                >
                  {err}
                </p>
              ))}
            </Col>
          )}
        </Row>
      ) : (
        <CustomSpinner />
      )}
    </Page>
  );
};

export default Register;
