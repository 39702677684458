import React, { useState } from "react";
import Page from "./Page";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Input from "../components/shared/Input";
import Button from "../components/shared/Button";
import { validateInput } from "../utils/validators";
import { authActions } from "../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { LOGIN_PAGE_URL } from "../constants/pagesConstants";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordMessage, setNewPasswordMessage] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordMessage, setConfirmNewPasswordMessage] =
    useState("");
  const { changePasswordError } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitFormHandler = (e) => {
    e.preventDefault();
    setNewPasswordMessage(null);
    setConfirmNewPasswordMessage(null);

    if (!validateInput(newPassword)) {
      setNewPasswordMessage("Please enter valid password");
      return;
    }

    if (!validateInput(confirmNewPassword)) {
      setConfirmNewPasswordMessage("Please enter valid confirm password");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordMessage("Please enter same passwords");
      return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);

    dispatch(
      authActions.changePasswordFetch({
        token: urlSearchParams.get("token"),
        email: "bronjaermin@gmail.com",
        newPassword,
        onSuccessChangedPassword,
      })
    );
  };

  const onSuccessChangedPassword = () => {
    navigate(LOGIN_PAGE_URL);
  };

  return (
    <Page className="center-alignment">
      <form onSubmit={submitFormHandler} className="w-100">
        <Row className="flex-column center-alignment">
          <Col lg="4" md="12" xs="12">
            <Input
              label="New password"
              labelVisibility
              type="password"
              placeholder="ex. Password"
              value={newPassword}
              setValue={setNewPassword}
              message={newPasswordMessage}
            />
          </Col>
          <Col lg="4" md="12" xs="12">
            <Input
              label="Confirm new passwor"
              type="password"
              labelVisibility
              placeholder="ex. Password"
              value={confirmNewPassword}
              setValue={setConfirmNewPassword}
              message={confirmNewPasswordMessage}
            />
          </Col>
          <Col lg="4" md="12" xs="12">
            <Button value="Change password" type="submit" />
          </Col>
          {changePasswordError && (
            <Col lg="4" md="12" xs="12">
              <p className="center-alignment mb-3 font-size-md color-danger text-center">
                {changePasswordError}
              </p>
            </Col>
          )}
        </Row>
      </form>
    </Page>
  );
};

export default ChangePassword;
