import { configureStore } from "@reduxjs/toolkit";
import configureSagaMiddleware from "redux-saga";
import { authReducers } from "./slices/authSlice";
import { transportReducers } from "./slices/transportSlice";
import { uiReducers } from "./slices/uiSlice";
import { locationReducers } from "./slices/locationSlice";

import rootSaga from "./saga";

const saga = configureSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authReducers,
    transport: transportReducers,
    ui: uiReducers,
    location: locationReducers,
  },
  middleware: [saga],
});

saga.run(rootSaga);
