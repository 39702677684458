// ROUTES

export const HOME_PAGE_URL_EMPTY = "/";
export const HOME_PAGE_URL = "/home";
export const ADMIN_PANEL_PAGE_URL = "/admin-panel/:id";
export const ABOUT_PAGE_URL = "/about";
export const CONTACT_PAGE_URL = "/contact";
export const TRANSPORTS_PAGE_URL = '/transports/:id'
export const LOGIN_PAGE_URL = "/login";
export const REGISTER_PAGE_URL = "/register";
export const CHANGE_PASSWORD_PAGE_URL = "/resetpassword";
export const EMAIL_CONFIRMATION_URL = "/emailconfirm";
export const DAILY_STATISTICS_URL = "/daily-statistics";
export const MONTHLY_STATISTICS_URL = "/monthly-statistics";
export const YEARLY_STATISTICS_URL = "/yearly-statistics";
export const EDIT_USER_URL = "/edit-user";

// LANGUAGES 
export const GERMANY_LANGUAGE = 'GERMANY_LANGUAGE'
export const FRANCE_LANGUAGE = 'FRANCE_LANGUAGE'