import React from "react";
import { Container } from "react-bootstrap";

const Page = ({ children, className, classNameContainer }) => {
  return (
    <div className={`${className} page pt-2`}>
      <Container className={`${classNameContainer}`}>{children}</Container>
    </div>
  );
};

export default Page;
