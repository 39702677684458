import React, { useEffect, useState } from "react";
import Page from "./Page";
import { transportActions } from "../store/slices/transportSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "../components/shared/CustomSpinner";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { TRANSPORTS_PAGE_URL } from "../constants/pagesConstants";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import ScrollToTop from "../components/shared/ScrollToTop";
import {
  IoMdCheckmark,
  IoMdCheckmarkCircleOutline,
  IoMdClose,
} from "react-icons/io";
import Modal from "../components/shared/Modal";
import Button from "../components/shared/Button";
import { uiActions } from "../store/slices/uiSlice";
import Input from "../components/shared/Input";
import { validateInput } from "../utils/validators";

const Transports = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    allTransportsLoading,
    allTransports,
    pagesNumber,
    allTransportsError,

    approveTransportLoading,
    approveTransportError,

    doneTransportLoading,
    doneTransportError,

    cancelTransportLoading,
    cancelTransportError,
  } = useSelector((state) => state.transport);
  const [confirmTransportModal, setConfirmTransportModal] = useState(false);
  const [doneTransportModal, setDoneTransportModal] = useState(false);
  const [cancelTransportModal, setCancelTransportModal] = useState(false);
  const [cancelationReason, setCancelationReason] = useState("");
  const [cancelationReasonMessage, setCancelationReasonMessage] =
    useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(uiActions.setActiveLink(TRANSPORTS_PAGE_URL));
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(
        transportActions.allTransportsFetch({ id, role: user.roles[0] })
      );
    }
  }, [user, dispatch, id]);

  const onNavigateHandler = (pageNumber) => {
    navigate(TRANSPORTS_PAGE_URL.replace(":id", Number(pageNumber)));
  };

  const onSuccessApproveTransport = () => {
    setConfirmTransportModal((oldState) => !oldState);
  };

  const onSuccessDoneTransport = () => {
    setDoneTransportModal((oldState) => !oldState);
  };

  const onSuccessCancelTransport = () => {
    setCancelTransportModal((oldState) => !oldState);
    setCancelationReason("");
  };

  const toggleConfirmTransportModal = (id) => {
    if (!confirmTransportModal) setSelectedId(id);
    else setSelectedId(null);

    setConfirmTransportModal((oldState) => !oldState);
  };

  const toggleCancelTransportModal = (id) => {
    if (!cancelTransportModal) setSelectedId(id);
    else {
      setSelectedId(null);
      setCancelationReason("");
      setCancelationReasonMessage(null);
    }

    setCancelTransportModal((oldState) => !oldState);
  };

  const toggleDoneTransportModal = (id) => {
    if (!doneTransportModal) setSelectedId(id);
    else setSelectedId(null);

    setDoneTransportModal((oldState) => !oldState);
  };

  const onCancelTransportHandler = () => {
    setCancelationReasonMessage(null);

    if (!validateInput(cancelationReason)) {
      setCancelationReasonMessage("Please enter reason");
      return;
    }

    dispatch(
      transportActions.cancelTransportFetch({
        id: selectedId,
        cancelationReason,
        onSuccessCancelTransport,
      })
    );
  };

  return (
    <Page>
      <ScrollToTop />

      {allTransportsLoading && !allTransportsError ? (
        <div className="center-alignment">
          <CustomSpinner />
        </div>
      ) : (
        <>
          {confirmTransportModal && (
            <Modal onClose={toggleConfirmTransportModal}>
              <div className="text-center custom-border-bottom-dark">
                <h4 className="color-dark">Alert</h4>
              </div>
              <div className="p-2 custom-border-bottom-dark">
                {!approveTransportLoading && (
                  <p className="font-size-md color-dark">
                    Are you sure to confirm transport?
                  </p>
                )}
                {approveTransportLoading && (
                  <div className="center-alignment">
                    <CustomSpinner />
                  </div>
                )}
                {!approveTransportLoading && approveTransportError && (
                  <p>{approveTransportError}</p>
                )}
              </div>
              <div className="modal-actions d-flex justify-content-end w-100">
                <div className="w-auto d-flex">
                  <Button
                    value="Close"
                    className={"mb-0 ms-2"}
                    onClick={toggleConfirmTransportModal}
                  />
                  <Button
                    value="Confirm"
                    className={"mb-0 ms-2"}
                    disabled={approveTransportLoading}
                    onClick={() =>
                      dispatch(
                        transportActions.approveTransportFetch({
                          id: selectedId,
                          onSuccessApproveTransport,
                        })
                      )
                    }
                  />
                </div>
              </div>
            </Modal>
          )}
          {cancelTransportModal && (
            <Modal onClose={toggleCancelTransportModal}>
              <div className="text-center custom-border-bottom-dark">
                <h4 className="color-dark">Alert</h4>
              </div>
              <div className="p-2 custom-border-bottom-dark">
                {!cancelTransportLoading && (
                  <>
                    <p className="font-size-md color-dark">
                      Are you sure to cancel transport?
                    </p>
                    <Input
                      labelClassName="color-dark"
                      label="Reason"
                      labelVisibility
                      placeholder="ex. Canceled trip"
                      value={cancelationReason}
                      setValue={setCancelationReason}
                      message={cancelationReasonMessage}
                    />
                  </>
                )}
                {cancelTransportLoading && (
                  <div className="center-alignment">
                    <CustomSpinner />
                  </div>
                )}
                {!cancelTransportLoading && cancelTransportError && (
                  <p>{cancelTransportError}</p>
                )}
              </div>
              <div className="modal-actions d-flex justify-content-end w-100">
                <div className="w-auto d-flex">
                  <Button
                    value="Close"
                    className={"mb-0 ms-2"}
                    onClick={toggleCancelTransportModal}
                  />
                  <Button
                    value="Confirm"
                    className={"mb-0 ms-2"}
                    disabled={cancelTransportLoading}
                    onClick={onCancelTransportHandler}
                  />
                </div>
              </div>
            </Modal>
          )}
          {doneTransportModal && (
            <Modal onClose={toggleDoneTransportModal}>
              <div className="text-center custom-border-bottom-dark">
                <h4 className="color-dark">Alert</h4>
              </div>
              <div className="p-2 custom-border-bottom-dark">
                {!doneTransportLoading && (
                  <p className="font-size-md color-dark">
                    Are you sure to done transport?
                  </p>
                )}
                {doneTransportLoading && (
                  <div className="center-alignment">
                    <CustomSpinner />
                  </div>
                )}
                {!doneTransportLoading && doneTransportError && (
                  <p>{doneTransportError}</p>
                )}
              </div>
              <div className="modal-actions d-flex justify-content-end w-100">
                <div className="w-auto d-flex">
                  <Button
                    value="Close"
                    className={"mb-0 ms-2"}
                    onClick={toggleDoneTransportModal}
                  />
                  <Button
                    value="Confirm"
                    className={"mb-0 ms-2"}
                    disabled={doneTransportLoading}
                    onClick={() =>
                      dispatch(
                        transportActions.doneTransportFetch({
                          id: selectedId,
                          onSuccessDoneTransport,
                        })
                      )
                    }
                  />
                </div>
              </div>
            </Modal>
          )}
          <div className="table-div">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Destination</th>
                  <th>Length/Price</th>
                  <th>Phone Number</th>
                  <th>Number of persons</th>
                  <th>Transport type</th>
                  <th>Vehicle type</th>
                  <th>Transport time</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {!allTransportsError &&
                  allTransports &&
                  allTransports.map((transport, index) => (
                    <tr key={index}>
                      <td>
                        {transport.firstName} {transport.lastName}
                      </td>
                      <td>Novi Pazar - Beograd</td>
                      <td>290km / {transport.price}&euro;</td>
                      <td>+381/65/684/5788</td>
                      <td>{transport.numberOfPersons}</td>
                      <td>{transport.transportType.name}</td>
                      <td>{transport.vehicleType.name}</td>
                      <td>
                        {new Date(transport.transportDateTime).getDate()}/
                        {new Date(transport.transportDateTime).getMonth() + 1}/
                        {new Date(transport.transportDateTime).getFullYear()}::
                        {new Date(transport.transportDateTime).getHours()}:
                        {new Date(transport.transportDateTime).getMinutes()}
                      </td>
                      <td className="d-flex align-items-center">
                        <div className="ms-1 me-1">
                          <button
                            disabled={
                              transport.transportIsDone ||
                              transport.canceled ||
                              transport.approved
                            }
                            onClick={() =>
                              toggleConfirmTransportModal(transport.id)
                            }
                          >
                            <IoMdCheckmark
                              className="cursor-pointer"
                              size={30}
                            />
                          </button>
                        </div>
                        <div className="ms-1 me-1">
                          <button
                            disabled={
                              transport.transportIsDone || transport.canceled
                            }
                            onClick={() =>
                              toggleDoneTransportModal(transport.id)
                            }
                          >
                            <IoMdCheckmarkCircleOutline
                              className="cursor-pointer"
                              size={30}
                            />
                          </button>
                        </div>
                        <div className="ms-1 me-1">
                          <button
                            disabled={
                              transport.transportIsDone || transport.canceled
                            }
                            onClick={() =>
                              toggleCancelTransportModal(transport.id)
                            }
                          >
                            <IoMdClose className="cursor-pointer" size={30} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="center-alignment mt-5">
            <button
              className="pagination-arrow me-3"
              disabled={id - 1 <= 0}
              onClick={() => onNavigateHandler(id - 1)}
            >
              <AiOutlineLeft size={30} />
            </button>
            <p
              className="pagination-page font-size-md font-bold"
              onClick={() => onNavigateHandler(1)}
            >
              1
            </p>
            <p className="pagination-page font-size-md font-bold">...</p>
            <p
              className="pagination-page font-size-md font-bold"
              onClick={() => onNavigateHandler(pagesNumber)}
            >
              {pagesNumber}
            </p>
            <button
              className="pagination-arrow ms-3"
              disabled={Number(id) === pagesNumber}
              onClick={() => onNavigateHandler(Number(id) + 1)}
            >
              <AiOutlineRight size={30} />
            </button>
          </div>
        </>
      )}
    </Page>
  );
};

export default Transports;
