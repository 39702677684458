import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locationLoading: false,
  locationFrom: null,
  locationTo: null,
  selectedLocationFrom: { id: null, latitude: null, longitude: null },
  selectedLocationTo: { id: null, latitude: null, longitude: null },
  locationError: null,

  calculateRouteLoading: false,
  routeCalculation: null,
  calculateRouteError: null,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    // SHARED
    resetLocations: (state) => {
      state.locationLoading = false;
      state.locationFrom = null;
      state.locationTo = null;
      state.selectedLocationFrom = {
        id: null,
        name: null,
        latitude: null,
        longitude: null,
      };
      state.selectedLocationTo = {
        id: null,
        name: null,
        latitude: null,
        longitude: null,
      };
      state.locationError = null;
      state.calculateRouteLoading = false;
      state.routeCalculation = null;
      state.calculateRouteError = null;
    },
    setSelectedLocationFrom: (state, action) => {
      state.selectedLocationFrom = {
        id: action.payload.id,
        name: action.payload.name,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
    },
    setSelectedLocationTo: (state, action) => {
      state.selectedLocationTo = {
        id: action.payload.id,
        name: action.payload.name,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
    },

    // LOCATION
    locationFetch: (state, action) => {
      state.locationLoading = true;
      if (!action.payload.place) state.locationFrom = null;
      else state.locationTo = null;
      state.locationError = null;
    },
    locationSuccess: (state, action) => {
      state.locationLoading = false;
      if (!action.payload.place) state.locationFrom = action.payload.data;
      else state.locationTo = action.payload.data;
      state.locationError = null;
    },
    locationError: (state, action) => {
      state.locationLoading = false;
      if (!action.payload.place) state.locationFrom = null;
      else state.locationTo = null;
      state.locationError = action.payload;
    },

    // CALCULATE ROUTE PRICE
    calculateRouteFetch: (state) => {
      state.calculateRouteLoading = true;
      state.routeCalculation = null;
      state.calculateRouteError = null;
    },
    calculateRouteSuccess: (state, action) => {
      state.calculateRouteLoading = false;
      state.routeCalculation = action.payload;
      state.calculateRouteError = null;
    },
    calculateRouteError: (state, action) => {
      state.calculateRouteLoading = false;
      state.routeCalculation = null;
      state.calculateRouteError = action.payload;
    },
  },
});

export const locationActions = locationSlice.actions;
export const locationReducers = locationSlice.reducer;
