import React from "react";

const Input = ({
  label,
  labelVisibility,
  type,
  placeholder,
  className,
  value,
  setValue,
  message,
  labelClassName,
  paragraphAbsolute,
  onClick = () => {},
}) => {
  return (
    <div className="mb-2">
      <label
        className={`font-size-md py-2 ${labelClassName}`}
        style={{ display: labelVisibility ? "block" : "none" }}
      >
        {label}
      </label>
      <input
        className={`custom-border-all-dark outline-none border-radius-md p-3 w-100 custom-bg-transparent ${className}`}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClick={onClick}
      />
      {message && (
        <p
          className={`font-italic text-danger ${
            paragraphAbsolute && "position-absolute"
          }`}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default Input;
