import axios from "axios";
import { locationEndpoints } from "../store/requests/apiEndpoints";

const methods = {
  location: (payload) =>
    axios
      .get(locationEndpoints.location.replace("{text}", payload.location))
      .then((response) => response.data),
  routeCalculation: (payload) =>
    axios
      .get(
        locationEndpoints.calculateRoute
          .replace("{longitude1}", payload.firstLongitude)
          .replace("{latitude1}", payload.firstLatitude)
          .replace("{longitude2}", payload.secondLongitude)
          .replace("{latitude2}", payload.secondLatitude)
      )
      .then((response) => response.data),
};

export default methods;
