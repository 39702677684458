import { createSlice } from "@reduxjs/toolkit";
import { GERMANY_LANGUAGE } from "../../constants/pagesConstants";

const initialState = {
  isShownModal: false,
  activeLink: '',
  activeLanguage: GERMANY_LANGUAGE
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    // MODAL TOGGLE
    toggleHomeModal: (state) => {
      state.isShownModal = !state.isShownModal;
    },
    // SET ACTIVE LINK
    setActiveLink: (state, action) => {
      state.activeLink = action.payload
    },
    // SET ACTIVE LANGUAGE
    setActiveLanguage: (state, action) => {
      state.activeLanguage = action.payload
    }
  },
});

export const uiActions = uiSlice.actions;
export const uiReducers = uiSlice.reducer;
