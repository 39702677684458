import React, { useCallback, useEffect } from "react";
import Page from "./Page";
import ScrollToTop from "../components/shared/ScrollToTop";
import CustomSpinner from "../components/shared/CustomSpinner";
import { useDispatch } from "react-redux";
import { authActions } from "../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE_URL_EMPTY } from "../constants/pagesConstants";

const EmailConfirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccessEmailConfirmation = useCallback(() => {
    navigate(HOME_PAGE_URL_EMPTY);
  }, [navigate]);

  const emailConfirmationHandler = useCallback(async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    let token = urlSearchParams.get("token");

    let decodedToken = decodeURIComponent(encodeURIComponent(token));
    let email = urlSearchParams.get("email");

    dispatch(
      authActions.confirmEmailFetch({
        token: decodedToken,
        email,
        onSuccessEmailConfirmation,
      })
    );
  }, [dispatch, onSuccessEmailConfirmation]);

  useEffect(() => {
    emailConfirmationHandler();
  }, [emailConfirmationHandler]);

  return (
    <Page className="center-alignment" classNameContainer="h-inherit">
      <ScrollToTop />
      <div className="center-alignment">
        <CustomSpinner />
      </div>
    </Page>
  );
};

export default EmailConfirm;
